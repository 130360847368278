import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const SubTabs = ({ tab: { subTabs }, path, location }) => {
  return (
    <div className="flex flex-col mt-3">
      {(subTabs || []).map((subTab, subTabIndex) => (
        <Link to={`/${subTab.url}`} key={subTabIndex}>
          <div className="item side-bar-item relative flex border-blue-700 px-6 cursor-pointer items-center mb-2">
            <div className="flex flex-none flex-col items-center side-bar-icon-team" />
            <span
              className={`label leading-tight ml-4 ${
                path.includes(subTab.url) ||
                location.search.includes(subTab.id)
                  ? "text-blue-700"
                  : ""
              }`}
            >
              {subTab.title || subTab.name}
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

SubTabs.defaultProps = {
  tab: {
    subTabs: [],
  },
  path: "",
  location: {
    search: "",
  },
};

SubTabs.propTypes = {
  tab: PropTypes.shape({
    subTabs: PropTypes.arrayOf(PropTypes.object),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  path: PropTypes.string,
};

export default SubTabs;
