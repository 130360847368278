/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import IframeResizer from "iframe-resizer-react";

const GIVE_UP_LOADING_TIME = 3000;

const MetabaseDashboard = ({ reportUrl }) => {
  const [loadingMetabase, setLoadingMetabase] = useState(true);

  useEffect(() => {
    const setTimeoutRef = setTimeout(
      () => setLoadingMetabase(false),
      GIVE_UP_LOADING_TIME,
    );

    return () => {
      clearTimeout(setTimeoutRef);
    };
  }, []);

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      {loadingMetabase && (
        <div className="flex justify-center items-center z-20 inset-0 h-full">
          <Loader type="Oval" color="grey" height={50} width={50} />
        </div>
      )}

      <IframeResizer
        className={loadingMetabase ? "invisible" : "visible"}
        id="metabase-iframe"
        title="Metabase Report"
        src={reportUrl}
        allowTransparency
        scrolling
        style={{ overflow: "auto", height: "100%" }}
      />
    </div>
  );
};

export default MetabaseDashboard;
