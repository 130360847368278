import React, { Component } from 'react';
import { Notification } from '../../helpers';
import AddUserForm from './add-user-form';
import addUserIcon from '../../assets/add-user.png';
import removeIcon from '../../assets/remove-blue.png';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';
import { SubmissionError } from 'redux-form';

class AddUserScreen extends Component {
  state = {
    isLoading: false
  };

  _closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  }

  _onSubmit = (values) => {
    const {
      first_name,
      last_name,
      email,
      title,
      bio,
      gender,
      role,
      is_active,
      time_from,
      time_to,
      countries,
      timezone,
      photo,
      phone_code,
      phone,
      work_days
    } = values;
    const { t } = this.props;
    this.setState({ isLoading: true });
    const formData = new FormData();

    formData.append('user[first_name]', first_name);
    formData.append('user[last_name]', last_name);
    formData.append('user[email]', email);
    formData.append('user[title]', title);
    formData.append('user[bio]', bio);
    formData.append('user[gender]', gender);
    formData.append('user[role]', role);
    formData.append('user[is_active]', is_active === '1');
    formData.append('user[time_from]', time_from);
    formData.append('user[time_to]', time_to);
    formData.append('user[is_active]', is_active);
    formData.append('user[timezone_id]', timezone.value);

    _.each(countries, country => formData.append('user[countries][][country_id]', country.value));
    _.each(work_days, (value, workDay) => value && formData.append('user[work_days][]', workDay));

    if (photo) 
      formData.append('user[photo]', photo);

    if (phone_code && phone) 
      formData.append('user[phone]', phone_code.value + ' ' + phone);

    return this.props.createUser(formData)
      .then(response => {
        Notification.success(t('alerts.success.user_added'));
        this._closeScreen();
      })
      .catch(error => {
        let errorMessages = {};
        this.setState({ isLoading: false });

        if (error.response.status === 422) {
          _.each(error.response.data.errors, (error, key) => {
            errorMessages[key] = _.join(error, '\n');
          });

          throw new SubmissionError(errorMessages);
        }
      });
  }

  render() {
    const { isLoading } = this.state;
    const { countries, timezones, t } = this.props;
    const countryOptions = _.map(countries, (country) => ({ value: country.id, label: country.name}));
    const timezoneOptions = _.map(timezones, (timezone) => ({ value: timezone.id, label: timezone.text}));

    return (
      <div className="h-screen flex overflow-hidden">
        <div className="flex flex-col side-screen bg-white border border-grey-400 overflow-hidden">
          <header className="h-20 flex items-center p-4 border-b border-grey-400">
            <div className="flex-none">
              <img
                src={addUserIcon}
                alt="Add User"
                className="add-user-icon"
                />
            </div>
            <div className="flex-1 flex flex-col">
              <span className="ml-4 text-base font-bold text-grey-700">{t('add_team_member.labels.add_team_member')}</span>
            </div>
            <div onClick={this._closeScreen} className="flex flex-col justify-center cursor-pointer">
              <img
                className="w-4 h-4"
                src={removeIcon} alt="Close"/>
            </div>
          </header>

          <AddUserForm
            showStatusAndRole
            submitButtonText={t('add_team_member.buttons.save_and_add')}
            onSubmit={this._onSubmit}
            countryOptions={countryOptions}
            timezoneOptions={timezoneOptions}/>
        </div>
        {
          isLoading ? (
            <div className="flex justify-center items-center z-20 absolute inset-0 bg-overlay-300">
              <Loader
                type="Oval"
                color="grey"
                height="50"
                width="50"
              />
            </div>
          ) : null
        }
      </div>
    )
  }
}

export default withTranslation()(AddUserScreen);
