import { API } from '../helpers';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function createUser(authToken, params) {
  return API.request({
    method: 'post',
    url: `${SERVER_URL}/users`,
    headers: {
      'X-SESSION-TOKEN': authToken,
      'Content-Type': 'multipart/form-data'
    },
    data: params,
    silent: true
  });
}

export function sendRegistrationEmail(authToken, id) {
  return API.request({
    method: 'post',
    url: `${SERVER_URL}/users/${id}/send_registration_email`,
    headers: {
      'X-SESSION-TOKEN': authToken
    }
  });
}

export function getUsers(authToken, params) {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/users`,
    headers: {
      'X-SESSION-TOKEN': authToken
    },
    params
  });
}
