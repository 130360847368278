import DashboardScreen from './dashboard-screen';
import { connect } from 'react-redux';
import { getTenants, getReports, generateReport, getReportDownloadURL } from '../../redux/thunk';

function mapStateToProps(state) {
  return {
    tenants: state.tenants.data,
    tenantsLoading: state.tenants.loading,
    reports: state.misc.reports,
  };
}

const mapDispatchToProps = {
  getTenants,
  getReports,
  generateReport,
  getReportDownloadURL
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
