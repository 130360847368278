import TeamsScreen from './teams-screen';
import { connect } from 'react-redux';
import { getUsers } from '../../redux/thunk';


function mapStateToProps(state) {
  return {
    users: state.users.data,
    totalUsers: state.users.total,
    activeUsers: state.users.active,
    matchedUsers: state.users.matched,
    deactivatedUsers: state.users.inactive,
    isNextPageLoading: state.users.loading,
    isAdmin: state.user.data.role === 1
  }
}

const mapDispatchToProps = {
  getUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsScreen);
