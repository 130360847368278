import React, { Component } from "react";
import SideBar from "../side-bar";
import Header from "../header";
import Loader from "react-loader-spinner";

class ProtectedLayout extends Component {
  state = {
    sidebarVisible: true,
    addScreenVisible: false,
    editScreenVisible: false,
    viewScreenVisible: false,
    isLoading: true,
  };

  componentDidMount() {
    Promise.all([
      this.props.checkSession(),
      this.props.getCountries(),
      this.props.getTimezones(),
      this.props.getMetabaseDashboards(),
    ])
      .then(response => {
        this.setState({ isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  }

  _toggleSideBar = () => {
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  };

  render() {
    const { sidebarVisible, isLoading } = this.state;
    const { children, location } = this.props;
    const subPath = location.pathname.split("/")[1];

    return (
      <div className="h-screen flex flex-row">
        <SideBar visible={sidebarVisible} />
        <div className="flex-1 flex p-6 pb-0 appearance-none">
          <div className="flex-1 flex-col flex">
            <Header
              onPressHamburger={this._toggleSideBar}
              subPath={subPath}
            />
            {isLoading ? null : children}
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center z-20 absolute inset-0 bg-overlay-300">
            <Loader type="Oval" color="grey" height="50" width="50" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ProtectedLayout;
