import React, { Component } from 'react';
import { Notification } from '../../helpers';
import EditUserForm from './edit-user-form';
import closeIcon from '../../assets/remove-blue.png';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';
import { SubmissionError } from 'redux-form';

class EditUserScreen extends Component {
  state = {
    isLoading: false
  };

  _closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  }

  _backToViewScreen = () => {
    this.props.onPressBackButton && this.props.onPressBackButton();
  }

  _onSubmit = (values) => {
    const {
      first_name,
      last_name,
      email,
      title,
      bio,
      gender,
      role,
      is_active,
      time_from,
      time_to,
      countries,
      timezone,
      photo,
      phone_code,
      phone,
      work_days
    } = values;
    const { t, user } = this.props;
    this.setState({ isLoading: true });
    const formData = new FormData();

    formData.append('user[first_name]', first_name);
    formData.append('user[last_name]', last_name);
    formData.append('user[email]', email);
    formData.append('user[title]', title);
    formData.append('user[bio]', bio);
    formData.append('user[gender]', gender);
    formData.append('user[role]', role);
    formData.append('user[is_active]', is_active === '1');
    formData.append('user[time_from]', time_from);
    formData.append('user[time_to]', time_to);
    formData.append('user[is_active]', is_active);
    formData.append('user[timezone_id]', timezone.value);

    _.each(countries, country => formData.append('user[countries][][country_id]', country.value));
    _.each(work_days, (value, workDay) => value && formData.append('user[work_days][]', workDay));

    if (phone_code && phone) 
      formData.append('user[phone]', phone_code.value + ' ' + phone);

    if (photo) {
      if (photo instanceof Object)
        formData.append('user[photo]', photo);
    }
    else
      formData.append('user[photo]', "");

    return this.props.updateUser(user.id, formData)
      .then(response => {
        Notification.success(t('alerts.success.user_updated'));
        this._closeScreen();
      })
      .catch(error => {
        let errorMessages = {};
        this.setState({ isLoading: false });

        if (error.response.status === 422) {
          _.each(error.response.data.errors, (error, key) => {
            errorMessages[key] = _.join(error, '\n');
          });

          throw new SubmissionError(errorMessages);
        }
      });
  }

  render() {
    const { isLoading } = this.state;
    const { countries, timezones, t, user } = this.props;
    const countryOptions = _.map(countries, (country) => ({ ...country, value: country.id, label: country.name}));
    const timezoneOptions = _.map(timezones, (timezone) => ({ ...timezone, value: timezone.id, label: timezone.text}));

    const splitPhoneNumber = user.phone ? user.phone.split(' ') : [];
    const work_days = [];
    _.each(user.work_days, work_day =>work_days[work_day] = true);

    const initialValues = {
      ..._.pick(user, ['first_name', 'last_name', 'email', 'title', 'bio', 'time_from', 'time_to', 'gender']),
      photo: user.photo_url,
      phone_code: splitPhoneNumber.length > 0 ? { label: splitPhoneNumber[0], value: splitPhoneNumber[0] } : null,
      phone: splitPhoneNumber.length > 0 ? splitPhoneNumber[1] : '',
      role: String(user.role),
      is_active: user.is_active ? "1" : "0",
      timezone: timezoneOptions.find((timezone) => timezone.id === user.timezone_id),
      countries: countryOptions.filter((country) => user.country_ids.includes(country.id)),
      work_days
    };

    return (
      <div className="h-screen flex overflow-hidden">
        <div className="flex flex-col side-screen bg-white border border-grey-400 overflow-hidden">
          <header className="flex flex-none h-20 p-4 border-b border-grey-400  items-center">
            <div className="flex-none">
              {
                user.photo_url ? (
                  <img alt={t('common.labels.profile_photo')} className="table-user-image object-cover" src={user.photo_url}/>
                ) : (
                  <div className="table-user-image"/>
                )
              }
            </div>
            <div className="flex-1 flex flex-col">
              <span className="ml-4 text-base font-bold text-grey-700">{user.first_name} {user.last_name}</span>
              <span className="ml-4 text-xs ont-bold text-grey-700">{user.title}</span>
            </div>
            <div onClick={this._closeScreen} className="flex flex-col justify-center cursor-pointer">
              <img
                className="w-4 h-4"
                src={closeIcon}
                alt={"close"}
              />
            </div>
          </header>

          <EditUserForm
            showStatusAndRole
            onPressBackButton={this._backToViewScreen}
            backButtonText={t('common.buttons.back_to_overview')}
            submitButtonText={t('common.buttons.save_changes')}
            initialValues={initialValues}
            onSubmit={this._onSubmit}
            countryOptions={countryOptions}
            timezoneOptions={timezoneOptions}/>
        </div>
        {
          isLoading ? (
            <div className="flex justify-center items-center z-20 absolute inset-0 bg-overlay-300">
              <Loader
                type="Oval"
                color="grey"
                height="50"
                width="50"
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default withTranslation()(EditUserScreen);
