import MySettingsScreen from './my-settings-screen';
import { connect } from 'react-redux';
import { updateCurrentUser, updatePassword, getCountries, getTimezones } from '../../redux/thunk';

function mapStateToProps(state) {
  return {
    countries: state.misc.countries,
    timezones: state.misc.timezones,
    user: state.user.data
  }
}

const mapDispatchToProps = {
  updateCurrentUser,
  getCountries,
  getTimezones,
  updatePassword
}

export default connect(mapStateToProps, mapDispatchToProps)(MySettingsScreen);
