import ResetPasswordScreen from './reset-password-screen';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux/thunk';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen);
