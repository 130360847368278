import { API } from '../helpers';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function getTenants(authToken) {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/tenants`,
    headers: {
      'X-SESSION-TOKEN': authToken
    }
  });
}
