import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { connect } from 'react-redux';

const UnAuthenticated = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      rest.user.authToken
        ? <Redirect to='/dashboard' />
        : <Component {...props} />
    );
  }} />
)

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(UnAuthenticated);
