import React, { Component } from 'react';
import DistrictSchools from './district-schools';
import Reports from './reports';

class DashboardScreen extends Component {
  state = {
    isLoading: true
  }

  componentDidMount() {
    this.props.getTenants();
    this.props.getReports();
  }

  render() {
    const {
      generateReport,
      getReportDownloadURL,
      tenants,
      tenantsLoading,
      reports
    } = this.props;

    return (
      <div className="flex-1 flex-col flex overflow-scroll pr-64">
        <DistrictSchools
          loading={tenantsLoading}
          tenants={tenants}/>

        <Reports
          generateReport={generateReport}
          getReportDownloadURL={getReportDownloadURL}
          loading={tenantsLoading}
          reports={reports}
          tenants={tenants}/>
      </div>
    );
  }
}

export default DashboardScreen;
