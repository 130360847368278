import { find } from "lodash";

export const getMetabaseUrl = (
  getMetabaseUrlRequest,
  dashboardID,
) => {
  return new Promise((resolve, reject) => {
    getMetabaseUrlRequest(
      { dashboard_id: dashboardID },
      resolve,
      reject,
    );
  });
};

export const getActiveDashboardObj = (
  dashboardId,
  metabaseDashboards,
) => {
  const activeDashboardObj = find(
    metabaseDashboards,
    d => d.id === parseInt(dashboardId, 10),
  );
  return activeDashboardObj;
};

export const addDashboardIdToUrl = (history, metabaseDashboards) => {
  const dashboardObj = metabaseDashboards[0];
  return history.replace(`reports?dashboard=${dashboardObj.id}`);
};
