import React, { Component } from 'react';
import {
  Button,
  InputField
} from '../../components';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Notification } from '../../helpers';
import validator from 'validator';
import _ from 'lodash';
import logo from '../../assets/cialfo-logo.png';
import { withTranslation } from 'react-i18next';

class SignInScreen extends Component {
  state = {
    email: '',
    password: '',
    errorMessages: {},
    isLoading: false
  }

  _onSubmit = (event) => {
    event.preventDefault();

    const { email, password } = this.state;
    const { t } = this.props;
    let errorMessages = {};

    if (!email)
      errorMessages['email'] = `Email ${t('validation.error.required')}`;
    else if (!validator.isEmail(email))
      errorMessages['email'] = t('validation.error.email_invalid');

    if (!password)
      errorMessages['password'] = `Password ${t('validation.error.required')}`;

    if (_.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages
      });

      return;
    }

    this.setState({ isLoading: true });
    this.props.signIn({ email, password })
      .then(response => {
        Notification.success("Signed In");
      })
      .catch(error => this.setState({ isLoading: false }));
  }

  render() {
    const { isLoading, errorMessages } = this.state;
    const { t } = this.props;

    return (
      <div className="h-screen flex flex-row bg-blue-700 justify-center items-center">
        <div className="w-3/4 max-w-lg">
          <form onSubmit={this._onSubmit} className="p-8 bg-white mb-6 rounded-lg shadow-lg">
            <div className="flex flex-row justify-center mb-4">
              <img
                className="w-98px h-36px"
                src={logo}
                alt="logo"/>
            </div>
            <div className="mb-4">
              <label className="font-bold text-grey-darker block mb-2">{t('common.labels.email')}</label>
              <InputField
                placeholder={t('common.placeholders.email')}
                name={"email"}
                error={errorMessages['email']}
                onChange={event => {
                  const errorMessages  = {...this.state.errorMessages};
                  errorMessages['email'] = null;
                  this.setState({ email: event.target.value, errorMessages });
                }}
              />
            </div>

            <div className="mb-4">
              <label className="font-bold text-grey-darker block mb-2">{t('common.labels.password')}</label>
              <InputField
                placeholder={t('common.placeholders.password')}
                type={'password'}
                name={"password"}
                error={errorMessages['password']}
                onChange={event => {
                  const errorMessages  = {...this.state.errorMessages};
                  errorMessages['password'] = null;
                  this.setState({ password: event.target.value, errorMessages });
                }}
              />
            </div>

            <div className="flex items-center justify-between">
              <Button
                type="submit"
                text={t('common.buttons.sign_in')}
              />
              <Link to="/forgot-password">
                <span className="no-underline inline-block align-baseline font-bold text-sm text-blue hover:text-blue-dark float-right" href="#">
                  {t('common.buttons.forgot_password')}
                </span>
              </Link>
            </div>
          </form>
        </div>
        {
          isLoading ? (
            <div className="flex justify-center items-center z-20 absolute inset-0 bg-overlay-300">
              <Loader
                type="Oval"
                color="grey"
                height="50"
                width="50"
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default withTranslation()(SignInScreen);
