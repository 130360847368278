import React, { Component } from 'react';
import {
  Button,
  InputField,
  Select,
  ToggleButton
} from '../../components';
import ThirdPartySelect from 'react-select';
import downArrow from '../../assets/down.png';
import _ from 'lodash';
import { Format } from '../../helpers';
import AddUserScreen from '../add-user';
import EditUserScreen from '../edit-user';
import ViewUserScreen from '../view-user'
import { Column, Table } from "react-virtualized";
import 'react-virtualized/styles.css';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';

class TeamsScreen extends Component {
  constructor(props) {
    super(props);

    const { t } = props;

    this.sortOptions = [
      { value: 'fn-asc', label: t('team.labels.first_name_abc') },
      { value: 'fn-desc', label: t('team.labels.first_name_zyx') },
      { value: 'ln-asc', label: t('team.labels.last_name_abc') },
      { value: 'ln-desc', label: t('team.labels.last_name_zyx') }
    ];

    this.state = {
      addScreenVisible: false,
      editScreenVisible: false,
      viewScreenVisible: false,
      searchName: '',
      filterRole: 0,
      sortBy: this.sortOptions[0],
      showActiveUsers: true,
      showInactiveUsers: false,
      isLoading: true,
      prevParams: {}
    }
  }

  componentDidMount() {
    this._loadUsers();
  }

  _loadUsers = (loadMore) => {
    const {
      searchName,
      filterRole,
      sortBy,
      prevParams,
      showActiveUsers,
      showInactiveUsers
    } = this.state;
    const params = {
      search: searchName,
      order: {
        by: _.startsWith(sortBy.value, 'fn') ? 'first_name' : 'last_name',
        desc: _.endsWith(sortBy.value, 'desc'),
      },
      role: filterRole
    };
    params['is_active'] = showActiveUsers !== showInactiveUsers ? showActiveUsers : undefined;

    if (loadMore === true || !_.isEqual(params, prevParams)) {
      if (!loadMore)
        this.setState({prevParams: params, isLoading: true});

      return this.props.getUsers(params)
        .then(response => {
          this.setState({ isLoading: false });

          return response;
        })
        .catch(error => {
          this.setState({ isLoading: false });

          throw error;
        });
    }
    else {
      return new Promise(function(resolve, reject) {
        resolve();
      });
    }
  }

  _loadMoreUsers = (params) => {
    return this._loadUsers(true);
  }

  _openAddUserScreen = () => {
    this.setState({ addScreeVisible: true });
  }

  _openViewUserScreen = (user) => {
    this.setState({
      viewScreenVisible: true,
      editScreenVisible: false,
      selectedUser: user
    });
  }

  _closeViewUserScreen = () => {
    this.setState({ viewScreenVisible: false });
  }

  _openEditUserScreen = () => {
    this.setState({ editScreenVisible: true });
  }

  _closeEditUserScreen = () => {
    this.setState({ editScreenVisible: false });
  }

  _rowGetter = ({index}) => this.props.users[index] || {};

  _searchLoadUsers = _.debounce(this._loadUsers, 1000);

  _search = (event) => {
    this.setState({
      searchName: event.target.value
    }, this._searchLoadUsers);
  }

  _nameHeaderRenderer = (params, title) => {
    return (
      <div className="flex table-name-column z-10 items-center">
        <span className="text-sm flex-none text-grey-800 font-bold mr-3">{title}</span>
        <div className="flex-1">
          <ThirdPartySelect
            isSearchable={false}
            styles={{
              control: (provided, state) => {
                return ({
                  ...provided,
                  borderWidth: 1,
                  borderColor: '#017BE2',
                  boxShadow: "none",
                  height: 25,
                  minHeight: 25,
                  width: 150
                })
              },
              menu: (provided, state) => ({
                ...provided,
                fontSize: 11,
                fontWeight: '500'
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                fontSize: 11,
                fontWeight: '500',
                padding: 0,
                paddingLeft: 8
              }),
              placeholder: (provided, state) => ({
                ...provided,
                color: '#1E1E1E',
                fontSize: 11,
                fontWeight: '500'
              })
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => <div className="px-4"><img src={downArrow} className="down-icon" alt="icon"/></div>
            }}
            placeholder="Code"
            defaultValue={this.sortOptions[0]}
            onChange={(sortBy) => this.setState({ sortBy }, this._loadUsers)}
            options={this.sortOptions}
          />
        </div>
      </div>
    )
  }

  _headerRenderer = (params, title) => {
    return (
      <div className="flex table-name-column">
        <span className="text-sm text-grey-800 font-bold">{title}</span>
      </div>
    )
  }

  _rowClassName = ({index}) => {
    if (index < 0) {
     return "";
    } else {
      return "user-table-row";
    }
  }

  render() {
    const {
      addScreeVisible,
      editScreenVisible,
      selectedOption,
      viewScreenVisible,
      selectedUser,
      showActiveUsers,
      showInactiveUsers
    } = this.state;
    const {
      isAdmin,
      users,
      matchedUsers,
      activeUsers,
      deactivatedUsers,
      isNextPageLoading,
      t
    } = this.props;
    const options = [
      { value: 0, label: t('team.roles.any') },
      { value: 1, label: t('team.roles.admin') },
      { value: 2, label: t('team.roles.member') }
    ];
    const hasNextPage = matchedUsers > users.length;
    const isRowLoaded = ({ index }) => !hasNextPage || index < users.length;
    const loadMoreRows = isNextPageLoading ? () => {} : this._loadMoreUsers;

    return (
      <div className="flex flex-1 mt-10 overflow-hidden">
        <div className="flex flex-col w-56 mr-4">
          <span className="text-sm text-grey-800 font-bold mb-2">{t('team.labels.status')}</span>

          <ToggleButton
            label={t('team.buttons.active')}
            count={activeUsers}
            value={showActiveUsers}
            onChange={(showActiveUsers) => this.setState({ showActiveUsers, showInactiveUsers: false }, this._loadUsers) }/>

          <div className="mt-1" />

          <ToggleButton
            label={t('team.buttons.deactivated')}
            count={deactivatedUsers}
            value={showInactiveUsers}
            onChange={(showInactiveUsers) => this.setState({ showInactiveUsers, showActiveUsers: false }, this._loadUsers) }/>

          <div className="item-group flex flex-col">
            <span className="text-sm text-grey-800 font-bold mb-2 mt-4">{t('team.labels.search_by_name')}</span>

            <InputField
              placeholder={t('team.placeholders.search_by_name')}
              onChange={this._search}/>
          </div>

          <div className="item-group flex flex-col">
            <span className="text-sm text-grey-800 font-bold mb-2 mt-4">{t('team.labels.filter_by_role')}</span>

            <Select
              placeholder={t('team.roles.any')}
              defaultValue={options[0]}
              value={selectedOption}
              onChange={(selectedOption) => {
                this.setState({
                  filterRole: selectedOption.value
                }, this._loadUsers);
              }}
              options={options}
            />
          </div>

          <div className="border-t border-gray-400 my-4"/>

          {
            isAdmin ? (
              <div className="flex flex-col">
                <Button onClick={this._openAddUserScreen} text={t('team.buttons.add_team_member')}/>
                <div className="border-t border-gray-400 my-4"/>
              </div>
            ) : null
          }
        </div>

        <div style={{ minWidth: '900px' }} className="flex-1 flex flex-col overflow-scroll">
          <div className="flex-1 h-full">
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={matchedUsers}>
              {({onRowsRendered, registerChild}) => {
                return (
                  <AutoSizer>
                  {
                    ({width, height}) => (
                      <Table
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        noRowsRenderer={() => {
                          return isNextPageLoading ? (
                            <div className="flex flex-col justify-center items-center  h-full w-full">
                              <Loader
                                type="Oval"
                                color="grey"
                                height="50"
                                width="50"
                              />
                            </div>
                          ) : null;
                        }}
                        headerHeight={40}
                        height={height}
                        rowClassName={this._rowClassName}
                        rowHeight={62}
                        rowGetter={this._rowGetter}
                        rowCount={users.length}
                        onRowClick={({rowData}) => {
                          this._openViewUserScreen(rowData);
                        }}
                        width={width}>
                        <Column
                          dataKey="name"
                          disableSort
                          headerRenderer={(params) => this._nameHeaderRenderer(params, t('team.labels.name'))}
                          width={width / 6 + 100}
                          cellRenderer={(params) => {
                            const { rowData } = params;
                            return (
                              <div className="flex flex-row items-center">
                                {
                                  rowData['photo_url'] ?
                                    <img src={rowData['photo_url']} alt="profile_photo" className="table-user-image object-cover"/>
                                  :
                                    <div className="flex-none table-user-image"/>
                                }
                                <span className="user-table-value-name">{rowData['first_name']} {rowData['last_name']}</span>
                              </div>
                            )
                          }}
                        />
                        <Column
                          dataKey="role"
                          disableSort
                          headerRenderer={(params) => this._headerRenderer(params, t('team.labels.district_role'))}
                          width={100}
                          cellRenderer={({cellData}) => {
                            const c = cellData === 1 ? "800" : "400";
                            return (
                              <div className={`rounded h-6 w-20 flex justify-center items-center px-2 bg-blue-${c} border border-blue-${c}`}>
                                <span className="text-sm text-white leading-none">{cellData === 1 ? t('team.roles.admin') : t('team.roles.member')}</span>
                              </div>
                            )
                          }}
                        />
                        <Column
                          dataKey="email"
                          disableSort
                          headerRenderer={(params) => this._headerRenderer(params, t('team.labels.email_address'))}
                          width={(width - 100) / 6}
                          flexGrow={1}
                          cellRenderer={({ cellData }) => {
                            return (
                              <div className="table-name-column">
                                <span className={`font-medium ${cellData ? 'text-blue-500 cursor-pointer' : 'text-grey-700'} text-sm leading-none`}>{Format.displayValue(cellData)}</span>
                              </div>
                            )
                          }}
                        />
                        <Column
                          dataKey="phone"
                          disableSort
                          headerRenderer={(params) => this._headerRenderer(params, t('team.labels.phone'))}
                          width={(width - 100) / 6}
                          cellRenderer={({cellData}) => {

                            return (
                              <div className="table-name-column">
                                <span className={`font-medium ${cellData ? 'text-blue-500 cursor-pointer' : 'text-grey-700'} text-sm leading-none`}>{Format.displayValue(cellData, null, Format.phoneNumber)}</span>
                              </div>
                            )
                          }}
                        />
                        <Column
                          dataKey="created_at"
                          disableSort
                          headerRenderer={(params) => this._headerRenderer(params, t('team.labels.added'))}
                          width={(width - 100) / 6 - 50}
                          cellRenderer={({cellData}) => {

                            return (
                              <div className="table-name-column">
                                <span className="font-medium text-sm leading-none text-grey-700">{Format.date(cellData)}</span>
                              </div>
                            )
                          }}
                        />
                        <Column
                          dataKey="last_sign_in_at"
                          disableSort
                          headerRenderer={(params) => this._headerRenderer(params, t('team.labels.last_login'))}
                          width={(width - 100) / 6 - 50}
                          cellRenderer={({cellData}) => {

                            return (
                              <div className="table-name-column">
                                <span className="font-medium text-sm leading-none text-grey-700">{Format.displayValue(cellData, null, Format.date)}</span>
                              </div>
                            )
                          }}
                        />
                      </Table>
                    )
                  }
                  </AutoSizer>
                )
              }}
            </InfiniteLoader>
          </div>
          {
            isNextPageLoading && users.length > 0 ? (
              <div className={"absolute bottom-0 self-center pointer-events-none flex-none flex flex-col justify-center items-center p-1"}>
                <Loader
                  type="ThreeDots"
                  color="grey"
                  height="75"
                  width="75"
                />
              </div>
            ) : null
          }
        </div>
        <div className={`fixed top-0 right-0 bottom-0 side-screen-container ${addScreeVisible ? '' : 'side-screen-collapsed'}`}>
          {
            addScreeVisible ? (
              <AddUserScreen closeButtonPressed={() => this.setState({ addScreeVisible: false })}/>
            ) : null
          }
        </div>
        <div className={`fixed top-0 right-0 bottom-0 side-screen-container ${viewScreenVisible ? '' : 'side-screen-collapsed'}`}>
          {
            viewScreenVisible ? (
              <ViewUserScreen
                user={selectedUser}
                closeButtonPressed={this._closeViewUserScreen}
                editButtonPressed={() => {
                  this._closeViewUserScreen();
                  this._openEditUserScreen();
                }}/>
            ): null
          }
        </div>
        <div className={`fixed top-0 right-0 bottom-0 side-screen-container ${editScreenVisible ? '' : 'side-screen-collapsed'}`}>
          {
            editScreenVisible ? (
              <EditUserScreen
                closeButtonPressed={this._closeEditUserScreen}
                onPressBackButton={() => {
                  this._closeEditUserScreen();
                  this._openViewUserScreen(selectedUser);

                }}
                user={selectedUser}/>
            ) : null
          }
        </div>
      </div>
    );
  }
}

export default withTranslation()(TeamsScreen);
