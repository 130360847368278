import EditUserScreen from './edit-user-screen';
import { connect } from 'react-redux';
import {
  getCountries,
  getTimezones,
  updateUser
} from '../../redux/thunk';

function mapStateToProps(state) {
  return {
    countries: state.misc.countries,
    timezones: state.misc.timezones
  }
}

const mapDispatchToProps = {
  getCountries,
  getTimezones,
  updateUser
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserScreen);
