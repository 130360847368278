import { UserForm, userFormValidation } from '../../forms';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('myProfileForm');

function mapStateToProps(state) {
  return {
    photo: selector(state, 'photo')
  }
}

export default withTranslation()(reduxForm({
  form: 'myProfileForm',  // a unique identifier for this form
  validate: userFormValidation
})(connect(mapStateToProps)(UserForm)));
