import { createReducer } from 'reduxsauce'
import { UsersTypes as Types, UserTypes } from '../actions';
import storeInitialState from '../store/initial-state';

export const INITIAL_STATE = storeInitialState.users;

export const clearUsers = (state, action) => {
  return {
    ...state,
    data: []
  };
}

export const getUsersFailure = (state, action) => {
  return {
    ...state,
    loading: false
  };
}

export const getUsersRequest = (state, action) => {
  return {
    ...state,
    loading: true
  };
}

export const getUsersSuccess = (state, action) => {
  const {
    usersData: {
        users,
        matched,
        total,
        active,
        inactive,
      },
      params
    } = action;

  return {
    data: params.page === 1 ? users : [...state.data, ...users],
    matched,
    total,
    active,
    inactive,
    params: { ...state.params, ...params },
    loading: false
  };
}

export const createUserFailure = (state, action) => {
  return {
    ...state,
    loading: false
  };
}

export const createUserRequest = (state, action) => {
  return {
    ...state,
    loading: true
  };
}

export const createUserSuccess = (state, action) => {
  const { user } = action;
  const { data } = state;

  return {
    ...state,
    data: [...data, user],
    loading: false
  };
}

export const updateUserFailure = (state, action) => {
  return {
    ...state,
    loading: false
  };
}

export const updateUserRequest = (state, action) => {
  return {
    ...state,
    loading: true
  };
}

export const updateUserSuccess = (state, action) => {
  const { user } = action;
  const { data } = state;

  return {
    ...state,
    data: data.map( u => u.id === user.id ? user : u),
    loading: false
  };
}

const logout = (state, action) => {
  return INITIAL_STATE;
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_USERS]:                  clearUsers,
  [Types.CREATE_USER_FAILURE]:          createUserFailure,
  [Types.CREATE_USER_REQUEST]:          createUserRequest,
  [Types.CREATE_USER_SUCCESS]:          createUserSuccess,
  [Types.GET_USERS_FAILURE]:            getUsersFailure,
  [Types.GET_USERS_REQUEST]:            getUsersRequest,
  [Types.GET_USERS_SUCCESS]:            getUsersSuccess,
  [Types.UPDATE_USER_FAILURE]:          updateUserFailure,
  [Types.UPDATE_USER_REQUEST]:          updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]:          updateUserSuccess,
  [UserTypes.LOGOUT]:                       logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
