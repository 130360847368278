import { API } from '../helpers';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function getCountries(authToken) {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/countries`,
    headers: {
      'X-SESSION-TOKEN': authToken
    }
  });
}

export function getTimezones(authToken) {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/timezones`,
    headers: {
      'X-SESSION-TOKEN': authToken
    }
  });
}

export function getReports(authToken) {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/reports`,
    headers: {
      'X-SESSION-TOKEN': authToken
    }
  });
}

export function generateReport(authToken, reportID, data) {
  return API.request({
    method: 'post',
    url: `${SERVER_URL}/reports/${reportID}/generate`,
    headers: {
      'X-SESSION-TOKEN': authToken
    },
    data: data
  });
}

export function getReportDownloadURL(authToken, reportID, lastGeneratedReportId, params) {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/reports/${reportID}/download_url/${lastGeneratedReportId}`,
    headers: {
      'X-SESSION-TOKEN': authToken
    },
    params
  });
}
