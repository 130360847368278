import Header from './header';
import { connect } from 'react-redux';
import { signOut } from '../../redux/thunk';

function mapStateToProps(state) {
  return {
    user: state.user.data
  };
}

const mapDispatchToProps = {
  signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
