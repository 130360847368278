import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getTenantsFailure:            null,
  getTenantsRequest:            null,
  getTenantsSuccess:            ['tenants'],
});

export const TenantsTypes = Types;
export const TenantsCreators = Creators;
