import { persistCombineReducers } from 'redux-persist';
import { reducer as formReducer } from 'redux-form'
import storage from 'redux-persist/es/storage';
import miscReducer from './misc.reducer';
import tenantsReducer from './tenants.reducer';
import userReducer from './user.reducer';
import usersReducer from './users.reducer';

const config = {
  key: 'root',
  storage,
  blacklist: ['form', 'misc', 'tenants', 'users']
};

const rootReducer = persistCombineReducers(config, {
  form:           formReducer,
  misc:           miscReducer,
  tenants:        tenantsReducer,
  user:           userReducer,
  users:          usersReducer
});

export default rootReducer;
