import React, { useEffect } from "react";
import Metabase from "./metabase";

const MetabaseScreen = ({
  metabaseDashboards,
  location,
  history,
  getMetabaseDashboards,
  getMetabaseDashboardUrl,
  metabaseDashboardUrl,
}) => {
  useEffect(() => {
    getMetabaseDashboards();
  }, []);

  return (
    <>
      {metabaseDashboards.length ? (
        <Metabase
          metabaseDashboards={metabaseDashboards}
          location={location}
          history={history}
          getMetabaseDashboardUrl={getMetabaseDashboardUrl}
          metabaseDashboardUrl={metabaseDashboardUrl}
        />
      ) : null}
    </>
  );
};

export default MetabaseScreen;
