import { createReducer } from "reduxsauce";
import { UserTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.user;

export const authenticationFailure = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

export const authenticationRequest = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

export const authenticationSuccess = (state, action) => {
  const { authResponse } = action;

  return {
    ...state,
    sessionId: authResponse.id,
    data: authResponse.user,
    district: authResponse.district,
    authToken: authResponse.token,
    loading: false,
  };
};

export const getUserSuccess = (state, action) => {
  const { user } = action;

  return {
    ...state,
    data: user,
  };
};

export const updateCurrentUserSuccess = (state, action) => {
  const { user } = action;

  return {
    ...state,
    data: user,
  };
};

export const getMetabaseDashboardsRequest = state => ({
  ...state,
  metabase: {
    ...state.metabase,
  },
});

export const getMetabaseDashboardsSuccess = (state, action) => {
  return {
    ...state,
    metabase: {
      ...state.metabase,
      dashboards: action.dashboards,
    },
  };
};

export const getMetabaseDashboardsFailure = state => ({
  ...state,
  metabase: {
    ...state.metabase,
    dashboards: [],
  },
});

export const getMetabaseUrlRequest = state => ({
  ...state,
  metabase: {
    ...state.metabase,
    url: null,
  },
});

export const getMetabaseUrlSuccess = (state, action) => ({
  ...state,
  metabase: {
    ...state.metabase,
    url: action.metabaseUrl,
  },
});

export const getMetabaseUrlFailure = state => ({
  ...state,
  metabase: {
    ...state.metabase,
    url: null,
  },
});

const logout = (state, action) => {
  return INITIAL_STATE;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.AUTHENTICATION_FAILURE]: authenticationFailure,
  [Types.AUTHENTICATION_REQUEST]: authenticationRequest,
  [Types.AUTHENTICATION_SUCCESS]: authenticationSuccess,

  [Types.GET_USER_SUCCESS]: getUserSuccess,

  [Types.UPDATE_CURRENT_USER_SUCCESS]: updateCurrentUserSuccess,

  [Types.GET_METABASE_DASHBOARDS_REQUEST]:
    getMetabaseDashboardsRequest,
  [Types.GET_METABASE_DASHBOARDS_SUCCESS]:
    getMetabaseDashboardsSuccess,
  [Types.GET_METABASE_DASHBOARDS_FAILURE]:
    getMetabaseDashboardsFailure,

  [Types.GET_METABASE_URL_REQUEST]: getMetabaseUrlRequest,
  [Types.GET_METABASE_URL_SUCCESS]: getMetabaseUrlSuccess,
  [Types.GET_METABASE_URL_FAILURE]: getMetabaseUrlFailure,
  [Types.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
