import SideBar from "./side-bar";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    user: state.user.data,
    district: state.user.district,
    metabaseDashboards: state.user.metabase.dashboards,
  };
}

export default connect(mapStateToProps)(SideBar);
