import React, { Component } from 'react';

class FileInputField extends Component {
  _onChange = (e) => {
    const { input: { onChange } } = this.props
    onChange(e.target.files[0])
  }

  render(){
    const {
      input,
      meta,
      ...rest
    } = this.props

    return(
      <input
        {...rest}
        type='file'
        accept='.jpg, .png, .jpeg'
        onChange={this._onChange}
      />
    )
  }
}

export default FileInputField
