import React, { Component } from 'react';
import cialfoLogo from '../../assets/cialfo-logo-white.svg';
import cog from '../../assets/cog.png';
import helpCenterIcon from '../../assets/help-center.png';
import chatWithCialfoIcon from '../../assets/chat-cialfo.png';
import communityIcon from '../../assets/community.png';
import chromeIcon from '../../assets/chrome.png';
import logoutIcon from '../../assets/logout.png';
import { withTranslation } from 'react-i18next';

class Menu extends Component {
  _onPressMySettings = () => {
    this.props.onPressMySettings && this.props.onPressMySettings();
  }

  _onPressLogout = () => {
    this.props.onPressLogout && this.props.onPressLogout();
  }

  render() {
    const { menuVisible, user, t } = this.props;

    return (
      <div className={`header-menu p-4 shadow-xl z-10 ${menuVisible ? '' : 'hidden'}`}>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <span className="text-white font-bold">{user.first_name} {user.last_name}</span>
            <span className="text-yellow-300 text-xs">{user.role === 1 ? t('common.roles.admin') : t('common.roles.member')}</span>
          </div>
          <img alt="logo" src={cialfoLogo}/>
        </div>

        <div className="header-menu-separator"/>

        <div onClick={this._onPressMySettings} className="cursor-pointer flex items-center">
          <img alt={"settings"} className="h-3 w-3 mr-2" src={cog}/>
          <div className="flex flex-col">
            <span className="text-white text-sm">{t('header.menu.my_settings')}</span>
          </div>
        </div>

        <div className="header-menu-separator"/>

        <div className="flex flex-col">
          <span className="text-xs font-bold text-yellow-300 mb-1">{t('header.menu.help')}</span>

          <div className="flex mb-1">
            <div className="flex flex-1 items-center">
              <img alt="help" className="h-3 w-3 mr-2" src={helpCenterIcon}/>
              <span className="text-white text-sm">{t('header.menu.help_center')}</span>
            </div>

            <div className="flex flex-1 items-center">
              <img alt="chat" className="h-3 w-3 mr-2" src={chatWithCialfoIcon}/>
              <span className="text-white text-sm">{t('header.menu.chat_with_cialfo')}</span>
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-1 items-center">
              <img alt="community" className="h-3 w-3 mr-2" src={communityIcon}/>
              <span className="text-white text-sm">{t('header.menu.community')}</span>
            </div>

            <div className="flex flex-1 items-center">
              <img alt="edi" className="h-3 w-3 mr-2" src={chromeIcon}/>
              <span className="text-white text-sm">{t('header.menu.edi_for_chrome')}</span>
            </div>
          </div>
        </div>

        <div className="header-menu-separator"/>

        <div onClick={this._onPressLogout} className="cursor-pointer flex items-center">
          <img alt="logout" className="header-logout-icon mr-2" src={logoutIcon}/>
          <div className="flex flex-col">
            <span className="text-white text-sm">{t('header.menu.logout')}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Menu);
