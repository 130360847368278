import axios from 'axios';
import _ from 'lodash';
import { Notification } from './';
import i18n from '../i18n';

export class API {
  static headers() {
    return {
      "Content-Type":   "application/json",
    }
  }

  static request(options) {
    options.headers = _.merge(this.headers(), options.headers);

    return axios(options)
      .catch(error => {
        if (options.silent === true) {
        }
        else if (options.customMessagesForCodes && error.response && options.customMessagesForCodes[error.response.status]) {
          Notification.error(options.customMessagesForCodes[error.response.status]);
        }
        else {
          if (error.response && error.response.status === 401) {
            if (_.endsWith(options.url, '/sessions'))
              Notification.error(i18n.t('alerts.failure.invalid_credentials'));
            else
              Notification.error(i18n.t('alerts.failure.session_expired'));
          }
          else {
            var errorMessages = ""

            if (error.response && error.response.data.errors)
              errorMessages = _.join(error.response.data.errors, "\n")
            else if (error.response && error.response.data.error)
              errorMessages = error.response.data.error
            else if (error.message === "Network Error")
              errorMessages = i18n.t('alerts.failure.internet_connection');
            else
              errorMessages = i18n.t('alerts.failure.unknown');

            Notification.error(errorMessages)
          }
        }

        throw error;
      });
  }
}
