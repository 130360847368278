import React, { Component } from 'react';
import {
  Button,
  InputField
} from '../../components';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Loader from 'react-loader-spinner';
import { Notification } from '../../helpers';
import _ from 'lodash';
import logo from '../../assets/cialfo-logo.png';
import { withTranslation } from 'react-i18next';

class ResetPasswordScreen extends Component {
  state = {
    password: '',
    confirmPassword: '',
    passwordResetSuccessful: false,
    isLoading: false,
    errorMessages: {}
  }

  _onSubmit = () => {
    const { password, confirmPassword } = this.state;
    const { t } = this.props;
    const queryParams = queryString.parse(this.props.location.search);
    let errorMessages = {};

    if (!password)
      errorMessages['password'] = `Password ${t('validation.error.required')}`;

    if (!confirmPassword)
      errorMessages['confirmPassword'] = `Password ${t('validation.error.required')}`;

    if (_.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages
      });

      return;
    }

    if (password !== confirmPassword)
      Notification.error(t('validation.error.password_mismatch'));
    else {
      this.setState({ isLoading: true });
      this.props.resetPassword({
        token: queryParams.token,
        password: password
      })
        .then(response => {
          this.setState({ passwordResetSuccessful: true, isLoading: false });
          Notification.success(t('alerts.success.password_updated'));
        })
        .catch(error => {
          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    const { passwordResetSuccessful, isLoading, errorMessages } = this.state;
    const { t } = this.props;

    if (passwordResetSuccessful)
      return <Redirect to="/login"/>

    return (
      <div className="h-screen flex flex-row bg-blue-700 justify-center items-center">
        <div className="w-3/4 max-w-lg">
          <div className="p-8 bg-white mb-6 rounded-lg shadow-lg">
            <div className="flex flex-row justify-center mb-4">
              <img
                className="w-98px h-36px"
                src={logo}
                alt="logo"/>
            </div>
            <div className="mb-4">
              <label className="font-bold text-grey-darker block mb-2">{t('common.labels.password')}</label>
              <InputField
                placeholder={t('common.placeholders.password')}
                type={'password'}
                name={"password"}
                error={errorMessages['password']}
                onChange={event => {
                  const errorMessages  = {...this.state.errorMessages};
                  errorMessages['password'] = null;
                  this.setState({ password: event.target.value, errorMessages });
                }}
              />
            </div>
            <div className="mb-4">
              <label className="font-bold text-grey-darker block mb-2">{t('common.labels.password_confirm')}</label>
              <InputField
                placeholder={t('common.placeholders.password_confirm')}
                type={'password'}
                name={"confirm-password"}
                error={errorMessages['confirmPassword']}
                onChange={event => {
                  const errorMessages  = {...this.state.errorMessages};
                  errorMessages['confirmPassword'] = null;
                  this.setState({ confirmPassword: event.target.value, errorMessages });
                }}
              />
            </div>

            <div className="flex items-center justify-between">
              <Button
                text={t('common.buttons.submit')}
                onClick={this._onSubmit}
              />
              <Link to="/login">
                <span className="no-underline inline-block align-baseline font-bold text-sm text-blue hover:text-blue-dark float-right" href="#">
                  {t('common.buttons.sign_in')}
                </span>
              </Link>
            </div>
          </div>
        </div>
        {
          isLoading ? (
            <div className="flex justify-center items-center z-20 absolute inset-0 bg-overlay-300">
              <Loader
                type="Oval"
                color="grey"
                height="50"
                width="50"
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default withTranslation()(ResetPasswordScreen);
