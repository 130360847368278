import moment from 'moment';
import { week } from '../constants/server.constants';
import i18n from '../i18n';

export class Format {
  static date(d, format = 'MMM DD, YYYY') {
    return moment(d).format(format);
  }

  static time(d, format = 'hh:mma') {
    return moment(d).format(format);
  }

  static timeRange(start, end, format = 'hh:mma') {
    return start && end ? (moment(start).format(format) + " - " + moment(end).format(format)) : i18n.t('common.labels.na');
  }

  static phoneNumber(phoneNumber) {
    return phoneNumber.replace(' ', '');
  }

  static week(daysOfWeek) {
    return daysOfWeek instanceof Array && daysOfWeek.length > 0 ? daysOfWeek.map((day) => week[day]).join(', ') : i18n.t('common.labels.na');
  }

  static displayValue(value, ifNull, formatFunction) {
    ifNull = ifNull || i18n.t('common.labels.na')
    return value ? (formatFunction ? formatFunction(value) : value) : ifNull;
  }
}
