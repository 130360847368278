import { connect } from "react-redux";
import Reports from "./screen";
import {
  getMetabaseDashboards,
  getMetabaseDashboardUrl,
} from "../../redux/thunk";

const mapStateToProps = state => {
  return {
    metabaseDashboards: state.user.metabase.dashboards,
    metabaseDashboardUrl: state.user.metabase.url,
  };
};

const mapDispatchToProps = {
  getMetabaseDashboards,
  getMetabaseDashboardUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
