import * as WebService from '../../web-services';
import { UserCreators } from '../actions';
import { UsersCreators } from '../actions';
import _ from 'lodash';

export function createUser(params) {
  return function(dispatch, getState) {
    const { user: { authToken } } = getState();

    return WebService.createUser(authToken, params)
      .then(response => {
        dispatch(UsersCreators.createUserSuccess(response.data));

        return response;
      })
      .catch(error => {
        dispatch(UsersCreators.createUserFailure());

        throw error;
      });
  }
}

export function sendRegistrationEmail(id) {
  return function(dispatch, getState) {
    const { user: { authToken } } = getState();

    return WebService.sendRegistrationEmail(authToken, id);
  }
}

export function getUsers(params) {
  return function(dispatch, getState) {
    const {
      user: { authToken },
      users: {
        data: currentUsers,
        params: currentParams
      }
    } = getState();
    dispatch(UsersCreators.getUsersRequest());

    const shouldLoadMoreUsers = currentUsers.length > 0 && _.isEqual(_.omit(currentParams, ['page', 'per_page']), params);

    if (!shouldLoadMoreUsers)
      dispatch(UsersCreators.clearUsers());

    const page = shouldLoadMoreUsers ? (currentParams.page + 1) : 1;

    const requestParams = new URLSearchParams();
    requestParams.append('page', page);
    requestParams.append('per_page', currentParams.per_page);
    requestParams.append('search', params.search);
    requestParams.append('order[by]', params.order.by);

    if (params.order.desc)
      requestParams.append('order[desc]', params.order.desc);

    if (params.role !== 0)
      requestParams.append('role', params.role);

    if (typeof params.is_active !== 'undefined')
      requestParams.append('is_active', params.is_active);

    return WebService.getUsers(authToken, requestParams)
      .then(response => {
        dispatch(UsersCreators.getUsersSuccess(response.data, { ...params, page }, ));

        return response;
      })
      .catch(error => {
        dispatch(UsersCreators.getUsersFailure());

        throw error;
      });
  }
}

export function updateUser(id, params) {
  return function(dispatch, getState) {
    const { user: { authToken, data: { id: currentUserId } } } = getState();

    return WebService.updateUser(authToken, id, params)
      .then(response => {
        dispatch(UsersCreators.updateUserSuccess(response.data));

        if (id === currentUserId)
          dispatch(UserCreators.updateCurrentUserSuccess(response.data));

        return response;
      })
      .catch(error => {
        dispatch(UsersCreators.getUsersFailure());

        throw error;
      });
  }
}
