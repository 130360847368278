import * as WebService from '../../web-services';
import { MiscCreators } from '../actions';

export function getCountries() {
  return function(dispatch, getState) {
    const { user: { authToken } } = getState();
    dispatch(MiscCreators.getCountriesRequest());

    return WebService.getCountries(authToken)
      .then(response => {
        dispatch(MiscCreators.getCountriesSuccess(response.data.countries));

        return response;
      })
      .catch(error => {
        dispatch(MiscCreators.getCountriesFailure());

        throw error;
      });
  }
}

export function getTimezones() {
  return function(dispatch, getState) {
    const { user: { authToken } } = getState();
    dispatch(MiscCreators.getTimezonesRequest());

    return WebService.getTimezones(authToken)
      .then(response => {
        dispatch(MiscCreators.getTimezonesSuccess(response.data.timezones));

        return response;
      })
      .catch(error => {
        dispatch(MiscCreators.getTimezonesFailure());

        throw error;
      });
  }
}

export function getReports() {
  return function(dispatch, getState) {
    const { user: { authToken } } = getState();
    dispatch(MiscCreators.getReportsRequest());

    return WebService.getReports(authToken)
      .then(response => {
        dispatch(MiscCreators.getReportsSuccess(response.data.reports));

        return response;
      })
      .catch(error => {
        dispatch(MiscCreators.getReportsFailure());

        throw error;
      });
  }
}

export function generateReport(reportId, params) {
  return function(dispatch, getState) {
    const { user: { authToken } } = getState();

    return WebService.generateReport(authToken, reportId, params);
  }
}

export function getReportDownloadURL(reportId, lastGeneratedReportId, params) {
  return function(dispatch, getState) {
    const { user: { authToken } } = getState();

    return WebService.getReportDownloadURL(authToken, reportId, lastGeneratedReportId, params);
  }
}
