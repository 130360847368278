import React, { Component } from "react";
import downArrow from "../../assets/down.png";
import hamburger from "../../assets/hamburger.png";
import MySettingsScreen from "../../screens/my-settings";
import Menu from "./menu";
import { Notification } from "../../helpers";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";

class Header extends Component {
  state = {
    menuVisible: false,
    mySettingsVisible: false,
    isLoading: false,
  };

  toggleSideBar = () => {
    this.props.onPressHamburger && this.props.onPressHamburger();
  };

  _toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible });
  };

  _closeMenu = () => {
    // this.setState({ menuVisible: false });
  };

  _onPressMySettings = () => {
    this._toggleMenu();
    this.setState({ mySettingsVisible: true });
  };

  closeMySettings = () => {
    // this.props.mySettingsPressed && this.props.mySettingsPressed();
    this.setState({ mySettingsVisible: false });
  };

  _signOut = () => {
    this.setState({ isLoading: true });
    this.props
      .signOut()
      .then(response => {
        this.setState({ isLoading: false });
        Notification.error("Signed Out");
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { menuVisible, mySettingsVisible, isLoading } = this.state;
    const { user, subPath, t } = this.props;

    return (
      <div className="relative pb-2">
        <header className="flex justify-between header">
          <div
            onClick={this.toggleSideBar}
            className="flex cursor-pointer items-center"
          >
            <img
              alt="menu"
              className="icon-hamburger"
              src={hamburger}
            />
            <span className="ml-2 text-blue-800 text-xl">
              {t(`header.${subPath}`)}
            </span>
          </div>

          <div
            onClick={this._toggleMenu}
            className="flex cursor-pointer items-center"
          >
            {user.photo_url ? (
              <img
                alt={t("common.labels.profile_photo")}
                className="header-user-image object-cover"
                src={user.photo_url}
              />
            ) : (
              <div className="header-user-image" />
            )}
            <div className="pl-4">
              <img className="down-icon" alt="menu" src={downArrow} />
            </div>
          </div>

          <Menu
            close={this._closeMenu}
            user={user}
            onPressMySettings={this._onPressMySettings}
            onPressLogout={this._signOut}
            menuVisible={menuVisible}
          />
        </header>
        <div
          className={`fixed top-0 right-0 bottom-0 side-screen-container ${mySettingsVisible ? "" : "side-screen-collapsed"}`}
        >
          {mySettingsVisible ? (
            <MySettingsScreen
              closeButtonPressed={this.closeMySettings}
            />
          ) : null}
        </div>
        {isLoading ? (
          <div
            className={`flex justify-center items-center z-20 fixed top-0 right-0 bottom-0 left-0 side-screen-container bg-overlay-300`}
          >
            <Loader type="Oval" color="grey" height="50" width="50" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(Header);
