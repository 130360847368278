import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCountriesFailure:    null,
  getCountriesRequest:    null,
  getCountriesSuccess:    ['countries'],
  getTimezonesFailure:    null,
  getTimezonesRequest:    null,
  getTimezonesSuccess:    ['timezones'],
  getReportsFailure:      null,
  getReportsRequest:      null,
  getReportsSuccess:      ['reports']
});

export const MiscTypes = Types;
export const MiscCreators = Creators;
