import React, { Component } from 'react';
import {
  CountryCodeSelect,
  InputField,
  Select
} from '../../components';
import MyProfileForm from './my-profile-form';
import cog from '../../assets/cog-blue.png';
import removeIcon from '../../assets/remove-blue.png';
import { Notification } from '../../helpers';
import _ from 'lodash';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import validator from 'validator';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';
import { SubmissionError } from 'redux-form';

const initialState = {
  selectedProfilePicture: null,
  first_name: '',
  last_name: '',
  email: '',
  title: '',
  selectedPhoneCode: null,
  phone: '',
  selectedCountries: [],
  selectedGender: "",
  biography: '',
  is_active: null,
  selectedTimezone: null,
  selectedTimeFrom: null,
  selectedTimeTo: null,
  selectedWorkingDays: [],
  selectedLanguage: 'en-US',
  errorMessages: {},
  changesMade: false,
  isLoading: false
};

class MySettingsScreen extends Component {
  constructor(props) {
    super(props);

    const { user, countries, timezones } = props;
    const selectedTimezone = timezones.find((timezone) => timezone.id === user.timezone_id);
    const splitPhoneNumber = user.phone ? user.phone.split(' ') : [];

    this.state = {
      ...initialState,
      tabIndex: 0,
      photo_url: user.photo_url,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      title: user.title,
      selectedPhoneCode: splitPhoneNumber.length > 0 ? { label: splitPhoneNumber[0], value: splitPhoneNumber[0] } : null,
      phone: splitPhoneNumber.length > 0 ? splitPhoneNumber[1] : '',
      selectedCountries: countries.filter((country) => user.country_ids.includes(country.id)).map(country => ({ label: country.name, value: country.id })),
      selectedGender: user.gender,
      biography: user.bio,
      is_active: user.is_active ? '1' : '0',
      selectedTimezone: selectedTimezone ? {value: selectedTimezone.id, label: selectedTimezone.text} : {value: user.timezone_id, label: user.timezone_id},
      selectedTimeFrom: user.time_from,
      selectedTimeTo: user.time_to,
      selectedWorkingDays: user.work_days,
      selectedLanguage: user.locale,
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  }

  componentDidMount() {
    this.props.getCountries();
    this.props.getTimezones();
  }

  _closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  }

  _selectPersonal = () => {
    this.setState({
      tabIndex: 0,
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
  }

  _selectPassword = () => {
    this.setState({ tabIndex: 1 });
  }

  _handleLanguageChange = changeEvent => {
    this.setState({
      selectedLanguage: changeEvent.target.value
    });
  };

  _submitUpdateUser = (values) => {
    const {
      first_name,
      last_name,
      email,
      title,
      bio,
      gender,
      role,
      is_active,
      time_from,
      time_to,
      countries,
      timezone,
      photo,
      phone_code,
      phone,
      work_days,
      locale
    } = values;
    const { t, user } = this.props;
    this.setState({ isLoading: true });
    const formData = new FormData();

    formData.append('user[first_name]', first_name);
    formData.append('user[last_name]', last_name);
    formData.append('user[email]', email);
    formData.append('user[title]', title);
    formData.append('user[bio]', bio);
    formData.append('user[gender]', gender);
    formData.append('user[role]', role);
    formData.append('user[is_active]', is_active === '1');
    formData.append('user[time_from]', time_from);
    formData.append('user[time_to]', time_to);
    formData.append('user[is_active]', is_active);
    formData.append('user[timezone_id]', timezone.value);
    formData.append('user[locale]', locale);

    _.each(countries, country => formData.append('user[countries][][country_id]', country.value));
    _.each(work_days, (value, workDay) => value && formData.append('user[work_days][]', workDay));

    if (phone_code && phone) 
      formData.append('user[phone]', phone_code.value + ' ' + phone);

    if (photo) {
      if (photo instanceof Object)
        formData.append('user[photo]', photo);
    }
    else
      formData.append('user[photo]', "");

    return this.props.updateCurrentUser(formData)
      .then(response => {
        Notification.success(t('alerts.success.settings_updated'));
        this._closeScreen();
      })
      .catch(error => {
        let errorMessages = {};
        this.setState({ isLoading: false });

        if (error.response.status === 422) {
          _.each(error.response.data.errors, (error, key) => {
            errorMessages[key] = _.join(error, '\n');
          });

          throw new SubmissionError(errorMessages);
        }
      });
  }

  _submitUpdatePassword = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    const { t } = this.props;
    let errorMessages = {}

    if (!currentPassword)
      errorMessages['currentPassword'] = `Current Password ${t('validation.error.required')}`;

    if (!newPassword)
      errorMessages['newPassword'] = `New Password ${t('validation.error.required')}`;

    if (!confirmPassword)
      errorMessages['confirmPassword'] = `Confirm Password ${t('validation.error.required')}`;

    if (_.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages
      });

      return;
    }

    if (newPassword !== confirmPassword) {
      Notification.error(t('validation.error.password_mismatch'));

      return;
    }

    this.setState({ isLoading: true });

    this.props.updatePassword({
      current_password: currentPassword,
      new_password: newPassword
    })
      .then(res => {
        Notification.success(t('alerts.success.password_updated'));
        this._closeScreen();
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  }

  _renderPersonal() {
    const { isLoading } = this.state;
    const { countries, timezones, t, user } = this.props;
    const countryOptions = _.map(countries, (country) => ({ ...country, value: country.id, label: country.name}));
    const timezoneOptions = _.map(timezones, (timezone) => ({ ...timezone, value: timezone.id, label: timezone.text}));

    const splitPhoneNumber = user.phone ? user.phone.split(' ') : [];
    const work_days = [];
    _.each(user.work_days, work_day =>work_days[work_day] = true);

    const initialValues = {
      ..._.pick(user, ['first_name', 'last_name', 'email', 'title', 'bio', 'time_from', 'time_to', 'gender', 'locale']),
      photo: user.photo_url,
      phone_code: splitPhoneNumber.length > 0 ? { label: splitPhoneNumber[0], value: splitPhoneNumber[0] } : null,
      phone: splitPhoneNumber.length > 0 ? splitPhoneNumber[1] : '',
      role: String(user.role),
      is_active: user.is_active ? "1" : "0",
      timezone: timezoneOptions.find((timezone) => timezone.id === user.timezone_id),
      countries: countryOptions.filter((country) => user.country_ids.includes(country.id)),
      work_days
    };

    return (
      <div className="flex flex-col overflow-hidden">
        <MyProfileForm
          submitButtonText={t('common.buttons.save_changes')}
          initialValues={initialValues}
          onSubmit={this._submitUpdateUser}
          countryOptions={countryOptions}
          timezoneOptions={timezoneOptions}
          showStatusAndRole={false}
          showLanguage={true}/>
      </div>
    );
  }

  _renderPassword() {
    const { errorMessages } = this.state;
    const { t } = this.props;

    return (
      <div className="flex flex-col overflow-hidden">
        <div className="flex flex-none items-center justify-between py-2 px-4 bg-yellow-100">
          {/*<div className="flex items-center cursor-pointer">
            <span className="leading-none text-sm font-bold text-brown-600">You have unsaved changes</span>
          </div>*/}
          <div />

          <div onClick={this._submitUpdatePassword} className="flex flex-col h-8 justify-center rounded-sm bg-yellow-700 hover:bg-yellow-400 px-4 cursor-pointer">
            <span className="leading-none flex text-sm text-white">{t('common.buttons.save_changes')}</span>
          </div>
        </div>
        <div className="flex flex-none flex-col p-6 border-b border-grey-400">
          <span className="text-sm text-grey-800 font-bold mb-2">{t('my_settings.labels.change_password')}</span>

          <div className="flex flex-row mb-5">
            <div className="flex-1 flex pt-3">
              <span className="text-sm text-grey-700 mb-2 leading-none">{t('my_settings.labels.current')}</span>
              <span className="text-sm text-red-500 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
            </div>
            <div className="flex-1_6">
              <InputField
                placeholder={t('common.labels.password_current')}
                type={'password'}
                name={'currentPassword'}
                error={errorMessages['currentPassword']}
                onChange={event => {
                  const errorMessages = {...this.state.errorMessages};
                  errorMessages['currentPassword'] = null;
                  this.setState({ currentPassword: event.target.value, errorMessages });
                }}/>
            </div>
          </div>

          <div className="flex flex-row mb-5">
            <div className="flex-1 flex pt-3">
              <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.password_new')}</span>
              <span className="text-sm text-red-500 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
            </div>
            <div className="flex-1_6">
              <InputField
                placeholder={t('my_settings.placeholders.password_new')}
                type={'password'}
                name={'newPassword'}
                error={errorMessages['newPassword']}
                onChange={event => {
                  const errorMessages = {...this.state.errorMessages};
                  errorMessages['newPassword'] = null;
                  this.setState({ newPassword: event.target.value, errorMessages });
                }}/>
            </div>
          </div>

          <div className="flex flex-row mb-5">
            <div className="flex-1 flex pt-3">
              <span className="text-sm text-grey-700 mb-2 leading-none">{t('my_settings.labels.confirm')}</span>
              <span className="text-sm text-red-500 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
            </div>
            <div className="flex-1_6">
              <InputField
                placeholder={t('my_settings.placeholders.password_confirm')}
                type={'password'}
                name={'confirmPassword'}
                error={errorMessages['confirmPassword']}
                onChange={event => {
                  const errorMessages = {...this.state.errorMessages};
                  errorMessages['confirmPassword'] = null;
                  this.setState({ confirmPassword: event.target.value, errorMessages });
                }}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { tabIndex, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div className="h-screen flex overflow-hidden">
        <div className="flex flex-col side-screen bg-white border border-grey-400 overflow-hidden">
          <div className="h-20 flex flex-none items-center p-4 border-b border-grey-400">
            <div className="flex-none">
              <img alt="settings" className="my-settings-icon" src={cog}/>
            </div>
            <div className="flex-1 flex flex-col">
              <span className="ml-2 text-base font-bold text-grey-700">{t('my_settings.labels.my_settings')}</span>
            </div>

            <button onClick={this._selectPersonal} className={`cursor-pointer btn-blue-round-8 mr-1 focus:outline-none ${ tabIndex === 1 ? ' bg-white text-blue-500' : '' }`}>
              {t('common.buttons.personal')}
            </button>

            <button onClick={this._selectPassword} className={`cursor-pointer btn-blue-round-8 mr-8 focus:outline-none ${ tabIndex === 0 ? ' bg-white text-blue-500 ' : '' }`}>
              {t('common.buttons.password')}
            </button>

            <div onClick={this._closeScreen} className="flex flex-col justify-center cursor-pointer">
              <img
                className="w-4 h-4"
                src={removeIcon}
                alt="close"/>
            </div>
          </div>
          {tabIndex === 0 ? this._renderPersonal() : this._renderPassword()}
        </div>
        {
          isLoading ? (
            <div className="flex justify-center items-center z-20 absolute inset-0 bg-overlay-300">
              <Loader
                type="Oval"
                color="grey"
                height="50"
                width="50"
              />
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default withTranslation()(MySettingsScreen);
