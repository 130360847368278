import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  clearUsers:                 null,
  createUserFailure:          null,
  createUserRequest:          ['user'],
  createUserSuccess:          ['user'],
  getUsersFailure:            null,
  getUsersRequest:            null,
  getUsersSuccess:            ['usersData', 'params'],
  updateUserFailure:          null,
  updateUserRequest:          null,
  updateUserSuccess:          ['user']
});

export const UsersTypes = Types;
export const UsersCreators = Creators;
