import React, { Component } from 'react';
import {
  Format,
  Notification
} from '../../helpers';
import removeIcon from '../../assets/remove-blue.png';
import moment from 'moment';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';

class ViewUserScreen extends Component {
  state = {
    sendingEmail: false
  }

  closeScreen = () => {
    this.props.closeButtonPressed && this.props.closeButtonPressed();
  }

  editButtonPressed = () => {
    this.props.editButtonPressed && this.props.editButtonPressed();
  }

  _sendRegistrationEmail = () => {
    const { user } = this.props;
    this.setState({ sendingEmail: true });

    this.props.sendRegistrationEmail(user.id)
      .then(res => {
        Notification.success('Registration email sent');
        this.setState({ sendingEmail: false });
      })
      .catch(error => {
        this.setState({ sendingEmail: false });
      });
  }

  render() {
    const { user, countries, timezones, isAdmin, t } = this.props;
    const { sendingEmail } = this.state;
    const timeFrom = user.time_from ? moment(user.time_from, 'HH:mm:ss') : null;
    const timeTo = user.time_to ? moment(user.time_to, 'HH:mm:ss') : null;
    const userCountries = countries.filter((country) => user.country_ids.includes(country.id)).map(country => country.name).join(', ');
    const userTimezone = timezones.find((timezone) => user.timezone_id === timezone.id);

    return (
      <div className="h-screen flex overflow-hidden">
        <div className="flex flex-col side-screen bg-white border border-grey-400 overflow-hidden">
          <header className="h-20 flex flex-none p-4 border-b border-grey-400 items-center">
            <div className="flex-none">
              {
                user.photo_url ? (
                  <img alt={t('common.labels.profile_photo')} className="table-user-image object-cover" src={user.photo_url}/>
                ) : (
                  <div className="table-user-image"/>
                )
              }
            </div>
            <div className="flex-1 flex flex-col">
              <span className="ml-4 text-base font-bold text-grey-700">{user.first_name} {user.last_name}</span>
              <span className="ml-4 text-xs ont-bold text-grey-700">{user.title}</span>
            </div>
            <div onClick={this.closeScreen} className="flex flex-col justify-center cursor-pointer">
              <img
                className="w-4 h-4"
                src={removeIcon}
                alt="close"
               />
            </div>
          </header>

          <div className="flex flex-col bg-white overflow-y-scroll">
            <div className="flex flex-none flex-col p-6 border-b border-grey-400">
              <div className="flex items-center mb-5">
                <span className="text-sm text-grey-800 font-bold leading-none">{t('common.labels.overview')}</span>
                {
                  isAdmin ? (
                    <button onClick={this.editButtonPressed} className={"ml-3 btn-blue-6 focus:outline-none"}>
                      {t('common.buttons.edit')}
                    </button>
                  ) : null
                }
                <button onClick={this._sendRegistrationEmail} className={"ml-3 btn-blue-6 focus:outline-none w-48 flex flex-row justify-center items-center"}>
                  {
                    sendingEmail ? (
                      <Loader
                        type="Oval"
                        color="#FFFFFF"
                        height="18"
                        width="18"
                      />
                    ) : <span>{t('common.buttons.send_registration_email')}</span>
                  }
                </button>
              </div>

              <div className="flex flex-1 flex-row mb-3">
                <div className="flex-1 flex flex-col pt-3">
                  <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.email')}</span>
                  <span className="font-bold text-blue-500 cursor-pointer text-sm">{user.email}</span>
                </div>
                <div className="flex-1">
                  <div className="flex-1 flex flex-col pt-3">
                    <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.status')}</span>
                    <button className={"flex justify-center items-center btn-green-6 focus:outline-none flex-none w-20 text-sm text-white" + (user.is_active ? '' : ' bg-grey-500')}>
                      {user.is_active ? t('common.labels.active'): t('common.labels.inactive')}
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-1 flex-row mb-3">
                <div className="flex-1 flex flex-col pt-3">
                  <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.phone')}</span>
                  <span className={`${user.phone ? 'text-blue-500 cursor-pointer' : ''} font-bold text-sm`}>{Format.displayValue(user.phone, null, Format.phoneNumber)}</span>
                </div>
                <div className="flex-1">
                  <div className="flex-1 flex flex-col pt-3">
                    <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.citizenships')}</span>
                    <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.displayValue(userCountries)}</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-1 flex-row mb-3">
                <div className="flex-1 flex flex-col pt-3">
                  <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.working_hours')}</span>
                  <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.timeRange(timeFrom, timeTo)}</span>
                </div>
                <div className="flex-1">
                  <div className="flex-1 flex flex-col pt-3">
                    <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.gender')}</span>
                    <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.displayValue(user.gender, null, _.startCase)}</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-1 flex-row mb-3">
                <div className="flex-1 flex flex-col pt-3">
                  <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.working_days')}</span>
                  <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.week(user.work_days)}</span>
                </div>
                <div className="flex-1">
                  <div className="flex-1 flex flex-col pt-3">
                    <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.timezone')}</span>
                    <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.displayValue(userTimezone.text)}</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-1 flex-row mb-3">
                <div className="flex-1 flex flex-col pt-3">
                  <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.biography')}</span>
                  <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.displayValue(user.bio)}</span>
                </div>
                <div className="flex-1">
                  <div className="flex-1 flex flex-col pt-3">
                    <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.district_role')}</span>
                    <div className="flex">
                    {
                      user.role === 2 ? (
                        <div className={`rounded-sm h-6 w-20 flex justify-center items-center px-2 bg-blue-400 border border-blue-400 mr-1`}>
                          <span className="text-sm text-white leading-none">{'Member'}</span>
                        </div>
                      ) : (
                        <div className={`rounded-sm h-6 w-20 flex justify-center items-center px-2 bg-blue-800 border border-blue-800`}>
                          <span className="text-sm text-white leading-none">{'Admin'}</span>
                        </div>
                      )
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-none flex-col p-6 border-b border-grey-400">
              <div className="flex items-center mb-5">
                <span className="text-sm text-grey-800 font-bold leading-none">{t('common.labels.activity')}</span>
              </div>
              <div className="flex flex-1 flex-row mb-3">
                <div className="flex-1 flex flex-col pt-3">
                  <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.account_added')}</span>
                  <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.displayValue(user.created_at, null, Format.date)}</span>
                </div>
                <div className="flex-1">
                  <div className="flex-1 flex flex-col pt-3">
                    <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.last_login')}</span>
                    <span className="font-bold text-grey-800 cursor-pointer text-sm">{Format.displayValue(user.last_sign_in_at, null, Format.date)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ViewUserScreen);
