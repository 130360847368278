import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import UnAuthenticated from "./routes/un-authenticated";
import ProtectedRoute from "./routes/protected";

//screens
import DashboardScreen from "./screens/dashboard";
import ForgotPasswordScreen from "./screens/forgot-password";
import ResetPasswordScreen from "./screens/reset-password";
import SignInScreen from "./screens/sign-in";
import TeamsScreen from "./screens/teams";
import Reports from "./screens/reports";
import i18n from "./i18n";
import "./icons";

class App extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;

    this.state = {
      locale: user ? user.locale : "en-US",
    };

    if (user && user.locale === "zh-CN") i18n.changeLanguage("zh-CN");
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const { user } = nextProps;

    if (user && user.locale !== nextState.locale) {
      i18n.changeLanguage(user.locale);

      return { locale: user.locale };
    } else return null;
  }

  _isReportsVisible = () => {
    const { district, metabaseDashboards } = this.props;
    return (
      district && metabaseDashboards && metabaseDashboards.length > 0
    );
  };

  render() {
    return (
      <Router>
        <Switch>
          <UnAuthenticated exact path="/" component={SignInScreen} />
          <UnAuthenticated
            exact
            path="/login"
            component={SignInScreen}
          />
          <UnAuthenticated
            exact
            path="/forgot-password"
            component={ForgotPasswordScreen}
          />
          <UnAuthenticated
            exact
            path="/reset-password"
            component={ResetPasswordScreen}
          />
          <ProtectedRoute
            exact
            path="/dashboard"
            component={DashboardScreen}
          />
          <ProtectedRoute
            exact
            path="/team"
            component={TeamsScreen}
          />

          {this._isReportsVisible() ? (
            <ProtectedRoute
              exact
              path="/reports"
              component={Reports}
            />
          ) : null}
          <Redirect from="*" to="/login" />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    district: state.user.district,
    metabaseDashboards: state.user.metabase.dashboards,
  };
}

export default connect(mapStateToProps)(App);
