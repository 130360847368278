import ForgotPasswordScreen from './forgot-password-screen';
import { connect } from 'react-redux';
import { forgotPassword } from '../../redux/thunk';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  forgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen);
