import React, { Component } from 'react';
import ThirdPartySelect, { components } from 'react-select';
import downArrow from '../../assets/down.png';
import countryPhoneCodes from '../../constants/country-phone-codes';
import _ from 'lodash';
const flags = _.sortBy(_.map(countryPhoneCodes, (countryPhoneCode, countryCode) => {
  return {
    value: countryPhoneCode,
    label: countryPhoneCode,
    flag: require(`../../assets/flags-png/${countryCode}.png`)
  };
}), option => option.label);

const singleOption = (props: OptionProps<any>) => (
  <components.Option {...props}>
    <div className="flex flex-row items-center">
      <img className={"w-6 h-6 object-contain"} alt="flag" src={props.data.flag}/>
      <span className="ml-2 text-sm text-gray-700 leading-none">{props.data.label}</span>
    </div>
  </components.Option>
)

const singleValue = (props: any) => (
  <components.SingleValue {...props}>
    <div className="ml-1 flex flex-row items-center">
      <img className={"w-6 h-6 object-contain"} alt="flag" src={props.data.flag}/>
      <span className="ml-2 text-sm text-gray-700 leading-none">{props.data.label}</span>
    </div>
  </components.SingleValue>
)

class CountryCodeSelect extends Component {
  constructor(props) {
    super(props);

    this.customStyles = {
      control: (provided, state) => {
        return ({
          ...provided,
          borderWidth: props.borderNone ? 0 : 1,
          borderColor: state.isFocused ? '#017BE2' : '#D8D8D8',
          boxShadow: "none",
          minHeight: 40,
          flex: 1
        })
      },
      menu: (provided, state) => ({
        ...provided,
        fontSize: 14,
        color: '#1E1E1E'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        fontSize: 14,
        color: '#1E1E1E'
      }),
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#017BE2',
        color: '#FFFFFF'
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#FFFFFF',
        fontSize: 14
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: '#FFFFFF',
        ':hover': {
          backgroundColor: '#017BE2',
          color: 'white',
        },
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#979797'
      })
    };
    this.customTheme = {
      borderRadius: 4
    }
  }

  render() {
    const { error, borderNone, value: valueFromProp } = this.props;
    let value = valueFromProp;

    if (value && !value.flag)
      value = _.find(flags, (flag) => flag.value === value.value);

    this.customStyles.control = (provided, state) => {
      return ({
        ...provided,
        borderWidth: borderNone ? 0 : 1,
        borderColor: error ? '#D0021B' : (state.isFocused ? '#017BE2' : '#D8D8D8'),
        backgroundColor: error ? '#FFE1E4' : '#FFFFFF',
        boxShadow: "none",
        minHeight: 40,
        flex: 1
      });
    }

    return (
      <div className="flex flex-col">

        <ThirdPartySelect
          isSearchable={false}
          placeholder="Code"
          options={flags}
          styles={this.customStyles}
          theme={this.customTheme}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <div className="pr-4 pl-1"><img src={downArrow} className="down-icon" alt="down"/></div>,
            SingleValue: singleValue,
            Option: singleOption
          }}
          {...this.props}
          value={value}
        />
        {
          error ? (
            <span className="input-field-error-text">
              {error}
            </span>
          ) : null
        }
      </div>
    );
  }
}

export default CountryCodeSelect;
