import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import Dashboard from "./dashboard";
import { UserCreators } from "../../redux/actions";
import { getActiveDashboardObj, addDashboardIdToUrl } from "./helper";

const Metabase = ({
  getMetabaseDashboardUrl,
  metabaseDashboards,
  history,
  location,
  metabaseDashboardUrl,
}) => {
  const [loadingMetabase, setLoadingMetabase] = useState(true);
  const [activeMetabaseDashboardObj, setActiveMetabaseDashboardObj] =
    useState({});
  const { dashboard: dashboardIdInUrl } = queryString.parse(
    location.search,
  );

  const setUpMetabase = async () => {
    try {
      setLoadingMetabase(true);

      if (!dashboardIdInUrl) {
        return addDashboardIdToUrl(history, metabaseDashboards);
      }

      const metabaseDashboardObj = getActiveDashboardObj(
        dashboardIdInUrl,
        metabaseDashboards,
      );

      getMetabaseDashboardUrl(dashboardIdInUrl);
      setLoadingMetabase(false);
      setActiveMetabaseDashboardObj(metabaseDashboardObj);
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e);
      setLoadingMetabase(false);
    }
    return null;
  };

  useEffect(() => {
    setUpMetabase();
  }, [dashboardIdInUrl]);

  const isDashboardReady = activeMetabaseDashboardObj.id;

  return (
    <>
      {isDashboardReady ? (
        <Dashboard
          key={activeMetabaseDashboardObj.id}
          dashboard={activeMetabaseDashboardObj.id}
          settingUpMetabase={loadingMetabase}
          reportUrl={metabaseDashboardUrl}
        />
      ) : null}
    </>
  );
};

const mapDispatchToProps = {
  getMetabaseUrlRequest: UserCreators.getMetabaseUrlRequest,
};

export default connect(null, mapDispatchToProps)(Metabase);
