import { find } from "lodash";

export const getTabs = ({ t, metabaseDashboards, district }) => {
  const tabs = [
    {
      url: "dashboard",
      title: t("side_bar.dashboard"),
      icon: ["fas", "table-columns"],
    },
    {
      url: "team",
      title: t("side_bar.team"),
      icon: ["fas", "users"],
    },
  ];

  if (
    district &&
    metabaseDashboards &&
    metabaseDashboards.length > 0
  ) {
    tabs.push({
      url: "reports",
      title: t("side_bar.reports"),
      icon: ["fas", "square-poll-vertical"],
      subTabs:
        metabaseDashboards &&
        metabaseDashboards.map(d => ({
          ...d,
          url: `reports?dashboard=${d.id}`,
        })),
    });
  }

  return tabs;
};

export const isTabSelected = ({ tab, path }) => {
  return (
    tab.url === path.split("/")[1] ||
    find(tab.subTabs || [], subTab => path.includes(subTab.url))
  );
};
