import { createReducer } from 'reduxsauce'
import { MiscTypes as Types, UserTypes } from '../actions';
import storeInitialState from '../store/initial-state';

export const INITIAL_STATE = storeInitialState.misc;

export const getCountriesFailure = (state, action) => {
  return {
    ...state,
    loading: false
  };
}

export const getCountriesRequest = (state, action) => {
  return {
    ...state,
    loading: true
  };
}

export const getCountriesSuccess = (state, action) => {
  const { countries } = action;

  return {
    ...state,
    countries,
    loading: false
  };
}

export const getTimezonesFailure = (state, action) => {
  return {
    ...state,
    loading: false
  };
}

export const getTimezonesRequest = (state, action) => {
  return {
    ...state,
    loading: true
  };
}

export const getTimezonesSuccess = (state, action) => {
  const { timezones } = action;

  return {
    ...state,
    timezones,
    loading: false
  };
}

export const getReportsFailure = (state, action) => {
  return {
    ...state,
    loading: false
  };
}

export const getReportsRequest = (state, action) => {
  return {
    ...state,
    loading: true
  };
}

export const getReportsSuccess = (state, action) => {
  const { reports } = action;

  return {
    ...state,
    reports,
    loading: false
  };
}

const logout = (state, action) => {
  return INITIAL_STATE;
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_COUNTRIES_FAILURE]:            getCountriesFailure,
  [Types.GET_COUNTRIES_REQUEST]:            getCountriesRequest,
  [Types.GET_COUNTRIES_SUCCESS]:            getCountriesSuccess,
  [Types.GET_TIMEZONES_FAILURE]:            getTimezonesFailure,
  [Types.GET_TIMEZONES_REQUEST]:            getTimezonesRequest,
  [Types.GET_TIMEZONES_SUCCESS]:            getTimezonesSuccess,
  [Types.GET_REPORTS_FAILURE]:              getReportsFailure,
  [Types.GET_REPORTS_REQUEST]:              getReportsRequest,
  [Types.GET_REPORTS_SUCCESS]:              getReportsSuccess,
  [UserTypes.LOGOUT]:                       logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
