import React, { Component } from 'react';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class DistrictSchools extends Component {
  render() {
    const { tenants, loading, t } = this.props;
    const currentYear = moment().get('year');
    const statisticsYears = _.range(currentYear + 3, currentYear - 1 , -1);

    return (
      <div className="flex flex-col flex-none max-w-4xl">
        <span className="flex-none font-bold text-base mt-8">{t('dashboard.district_schools')}</span>

        <div className="dashboard-table-header">
          <div className="flex flex-row flex-1 justify-between table-name-column mr-10px pl-1 pr-4">
            <span className="text-sm text-grey-800 font-bold">{t('dashboard.district_school')}</span>
            <span className="text-sm text-grey-800 font-bold">{t('dashboard.class')}</span>
          </div>
          {
            statisticsYears.map((statYear) => (
              <div key={statYear} className="flex-none w-16 table-name-column mr-10px">
                <span className="text-sm text-grey-800 font-bold">{String(statYear)}</span>
              </div>
            ))
          }
          <div className="flex-none w-20 table-name-column">
            <span className="text-sm text-grey-800 font-bold">{t('common.buttons.sign_in')}</span>
          </div>
        </div>
        <div className="flex flex-col flex-none">
          {
            tenants.map(tenant => (
              <div key={tenant.id} className="flex flex-row flex-none dashboard-table-row">
                <div className="flex flex-row flex-1 items-center pl-1 mr-10px">
                  <span className="font-medium text-sm text-grey-800">{tenant.name}</span>
                </div>
                {
                  statisticsYears.map((statYear) => {
                    const stat = _.find(tenant.statistics, (stat) => stat.key === 'students' && stat.tag === String(statYear));

                    return (
                      <div key={statYear} className="flex flex-row flex-none w-16 items-center mr-10px">
                        <span className="font-medium text-sm text-grey-600">{stat ? stat.value : ''}</span>
                      </div>
                    )
                  })
                }
                <div className="flex flex-row flex-none w-20 items-center">
                  <a
                    href={tenant.url + "/signin"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer flex flex-row flex-none w-20 items-center bg-blue-500 hover:bg-blue-600 text-white font-bold h-6 rounded justify-center"
                  >
                    <span className="font-medium text-sm leading-none">{t('common.buttons.sign_in')}</span>
                  </a>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withTranslation()(DistrictSchools);
