import * as WebService from "../../web-services";
import { UserCreators } from "../actions";

export function signIn(params) {
  return function (dispatch, getState) {
    return WebService.signIn(params).then(response => {
      dispatch(UserCreators.authenticationSuccess(response.data));
    });
  };
}

export function getMetabaseDashboards() {
  return function (dispatch, getState) {
    const {
      user: { authToken },
    } = getState();
    dispatch(UserCreators.getMetabaseDashboardsRequest());

    return WebService.metabaseDashboards(authToken)
      .then(response => {
        dispatch(
          UserCreators.getMetabaseDashboardsSuccess(
            response.data.dashboards,
          ),
        );

        return response;
      })
      .catch(error => {
        dispatch(UserCreators.getMetabaseDashboardsFailure());

        throw error;
      });
  };
}

export function getMetabaseDashboardUrl(id) {
  return function (dispatch, getState) {
    const {
      user: { authToken },
    } = getState();

    dispatch(UserCreators.getMetabaseUrlRequest());

    return WebService.metabaseDashboardUrl(authToken, id)
      .then(response => {
        dispatch(
          UserCreators.getMetabaseUrlSuccess(response.data.iframeUrl),
        );

        return response;
      })
      .catch(error => {
        dispatch(UserCreators.getMetabaseUrlFailure());

        throw error;
      });
  };
}

export function checkSession(params) {
  return function (dispatch, getState) {
    const {
      user: { authToken, sessionId },
    } = getState();

    return WebService.checkSession(authToken, sessionId).then(
      response => {
        dispatch(UserCreators.authenticationSuccess(response.data));
      },
    );
  };
}

export function signOut(params) {
  return function (dispatch, getState) {
    const {
      user: { authToken, sessionId },
    } = getState();

    return WebService.signOut(authToken, sessionId).then(response => {
      dispatch(UserCreators.logout(response.data));
    });
  };
}

export function forgotPassword(params) {
  return function (dispatch, getState) {
    return WebService.forgotPassword(params);
  };
}

export function resetPassword(params) {
  return function (dispatch, getState) {
    return WebService.resetPassword(params);
  };
}

export function updatePassword(params) {
  return function (dispatch, getState) {
    const {
      user: { authToken },
    } = getState();

    return WebService.updatePassword(authToken, params);
  };
}

export function getCurrentUser() {
  return function (dispatch, getState) {
    const {
      user: {
        authToken,
        data: { id },
      },
    } = getState();

    return WebService.getUser(authToken, id).then(response => {
      dispatch(UserCreators.getUserSuccess(response.data));

      return response;
    });
  };
}

export function updateCurrentUser(params) {
  return function (dispatch, getState) {
    const {
      user: {
        authToken,
        data: { id },
      },
    } = getState();

    return WebService.updateUser(authToken, id, params).then(
      response => {
        dispatch(
          UserCreators.updateCurrentUserSuccess(response.data),
        );

        return response;
      },
    );
  };
}
