import { createReducer } from 'reduxsauce'
import { TenantsTypes as Types, UserTypes } from '../actions';
import storeInitialState from '../store/initial-state';

export const INITIAL_STATE = storeInitialState.tenants;

export const getTenantsFailure = (state, action) => {
  return {
    ...state,
    loading: false
  };
}

export const getTenantsRequest = (state, action) => {
  return {
    ...state,
    loading: true
  };
}

export const getTenantsSuccess = (state, action) => {
  const { tenants } = action;

  return {
    ...state,
    data: tenants,
    loading: false
  };
}

const logout = (state, action) => {
  return INITIAL_STATE;
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_TENANTS_FAILURE]:            getTenantsFailure,
  [Types.GET_TENANTS_REQUEST]:            getTenantsRequest,
  [Types.GET_TENANTS_SUCCESS]:            getTenantsSuccess,
  [UserTypes.LOGOUT]:                   logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
