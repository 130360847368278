import React, { Component } from 'react';
import PropTypes from "prop-types";

class Button extends Component {
  render() {
    const { text } = this.props;
    return (
      <button {...this.props} className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-2 px-4 rounded focus:outline-none cursor-pointer">
        {text}
      </button>
    );
  }
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default Button;
