import React, { Component } from 'react';

class ToggleButton extends Component {
  _toggleSelection = () => {
    const { onChange, value } = this.props;

    onChange && onChange(!value);
  }

  render() {
    const { label, count } = this.props;
    const { value } = this.props;

    return (
      <div
        onClick={this._toggleSelection}
        className={value ? "btn-toggle-on" : "btn-toggle-off"}>
        <span className="btn-toggle-label">{label}</span>
        <span className="btn-toggle-value">{ count }</span>
      </div>
    );
  }
}

export default ToggleButton;
