import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Tab = ({ tab: { url, title, icon }, selectedTab }) => {
  return (
    <Link to={`/${url}`}>
      <div
        className={`side-bar-item relative flex h-36px border-blue-500 px-6 py-1 cursor-pointer items-center mb-2`}
      >
        {selectedTab ? (
          <div className="flex-none absolute top-0 bottom-0 left-0 w-1 bg-blue-500" />
        ) : null}

        <div className="flex flex-none flex-col items-center icon-side-bar">
          <FontAwesomeIcon
            icon={icon}
            className={`text-xl ${
              selectedTab ? "text-blue-700" : "text-gray-750"
            }`}
          />
        </div>

        <span
          className={`ml-4 ${selectedTab ? "text-blue-500" : ""}`}
        >
          {title}
        </span>
      </div>
    </Link>
  );
};

export default Tab;
