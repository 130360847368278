import ProtectedLayout from "./protected-layout";
import { connect } from "react-redux";
import {
  getCountries,
  getTimezones,
  checkSession,
  getMetabaseDashboards,
} from "../../redux/thunk";

function mapStateToProps(state) {
  return {
    users: state.users.data,
  };
}

const mapDispatchToProps = {
  getCountries,
  getTimezones,
  checkSession,
  getMetabaseDashboards,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtectedLayout);
