import ViewUserScreen from './view-user-screen';
import { connect } from 'react-redux';
import { sendRegistrationEmail } from '../../redux/thunk';

function mapStateToProps(state) {
  return {
    countries: state.misc.countries,
    timezones: state.misc.timezones,
    isAdmin: state.user.data.role === 1
  }
}

const mapDispatchToProps = {
  sendRegistrationEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserScreen);
