import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  authenticationFailure: null,
  authenticationRequest: ["authParams"],
  authenticationSuccess: ["authResponse"],

  getUserSuccess: ["user"],

  updateCurrentUserFailure: null,
  updateCurrentUserRequest: null,
  updateCurrentUserSuccess: ["user"],

  getMetabaseDashboardsRequest: null,
  getMetabaseDashboardsSuccess: ["dashboards"],
  getMetabaseDashboardsFailure: null,

  getMetabaseUrlRequest: ["id"],
  getMetabaseUrlSuccess: ["metabaseUrl"],
  getMetabaseUrlFailure: null,

  logout: null,
});

export const UserTypes = Types;
export const UserCreators = Creators;
