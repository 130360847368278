import React, { Component } from 'react';
import ThirdPartySelect from 'react-select';
import downArrow from '../../assets/down.png';

class Select extends Component {
  constructor(props) {
    super(props);

    this.customStyles = {
      control: (provided, state) => {
        return ({
          ...provided,
          borderWidth: props.borderNone ? 0 : 1,
          borderColor: state.isFocused ? '#017BE2' : '#D8D8D8',
          boxShadow: "none",
          minHeight: 40,
          flex: 1
        })
      },
      menu: (provided, state) => ({
        ...provided,
        fontSize: 14,
        color: '#1E1E1E'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        fontSize: 14,
        color: '#1E1E1E'
      }),
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#017BE2',
        color: '#FFFFFF'
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#FFFFFF',
        fontSize: 14
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: '#FFFFFF',
        ':hover': {
          backgroundColor: '#017BE2',
          color: 'white',
        },
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#979797'
      })
    };
    this.customTheme = {
      borderRadius: 4
    }
  }

  render() {
    const { error, borderNone } = this.props;
    this.customStyles.control = (provided, state) => {
      return ({
        ...provided,
        borderWidth: borderNone ? 0 : 1,
        borderColor: error ? '#D0021B' : (state.isFocused ? '#017BE2' : '#D8D8D8'),
        backgroundColor: error ? '#FFE1E4' : '#FFFFFF',
        boxShadow: "none",
        minHeight: 40,
        flex: 1
      });
    }

    return (
      <div className="flex flex-col">
        <ThirdPartySelect
          styles={this.customStyles}
          theme={this.customTheme}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <div className="px-4"><img src={downArrow} className="down-icon" alt="down"/></div>
          }}
          {...this.props}
        />
        {
          error ? (
            <span className="input-field-error-text">
              {error}
            </span>
          ) : null
        }
      </div>
    );
  }
}

export default Select;
