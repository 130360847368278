import { API } from "../helpers";
import i18n from "../i18n";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function signIn(params) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/sessions`,
    data: params,
  });
}

export function checkSession(authToken, sessionId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/sessions/${sessionId}`,
    headers: {
      "X-SESSION-TOKEN": authToken,
    },
  });
}

export function signOut(authToken, sessionId) {
  return API.request({
    method: "delete",
    url: `${SERVER_URL}/sessions/${sessionId}`,
    headers: {
      "X-SESSION-TOKEN": authToken,
    },
  });
}

export function forgotPassword(params) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/users/forgot_password`,
    data: params,
    customMessagesForCodes: {
      404: i18n.t("alerts.failure.user_not_found"),
    },
  });
}

export function resetPassword(params) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/users/reset_password`,
    data: params,
  });
}

export function updatePassword(authToken, params) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/users/update_password`,
    headers: {
      "X-SESSION-TOKEN": authToken,
    },
    data: params,
    customMessagesForCodes: {
      404: i18n.t("validation.error.password_mismatch"),
    },
  });
}

export function getUser(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/users/${id}`,
    headers: {
      "X-SESSION-TOKEN": authToken,
    },
    customMessagesForCodes: {
      404: i18n.t("alerts.failure.user_not_found"),
    },
  });
}

export function updateUser(authToken, id, params) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/users/${id}`,
    headers: {
      "X-SESSION-TOKEN": authToken,
      "Content-Type": "multipart/form-data",
    },
    data: params,
    silent: true,
  });
}

export function metabaseDashboards(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/metabase_dashboards`,
    headers: {
      "X-SESSION-TOKEN": authToken,
    },
  });
}

export function metabaseDashboardUrl(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/metabase_dashboards/${id}/embeded_url`,
    headers: {
      "X-SESSION-TOKEN": authToken,
    },
  });
}
