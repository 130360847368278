import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { connect } from 'react-redux';
import ProtectedLayout from '../layouts/protected';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      rest.user.authToken
        ?  (
          <ProtectedLayout {...props}>
            <Component {...props} />
          </ProtectedLayout>
        )
        : <Redirect to='/login' />
    );
  }} />
)

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(PrivateRoute);
