import React, { Component } from 'react';
import {
  CountryCodeSelect,
  FileInputField,
  InputField,
  Select
} from '../../components';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import validator from 'validator';
import { Field } from 'redux-form';
import backIcon from '../../assets/left.png';

const RenderSelectInput = (props) => {
  const { input, meta, ...rest } = props;

  return (
    <Select
      {...input}
      {...rest}
      error={meta.touched && meta.error}
      onChange={(value) => input.onChange(value)}
      onBlur={(value) => input.onBlur(input.value)}
    />
  );
}

const RenderCountrySelectInput = (props) => {
  const { input, meta, ...rest } = props;

  return (
    <CountryCodeSelect
      {...input}
      {...rest}
      error={meta.touched && meta.error}
      onChange={(value) => input.onChange(value)}
      onBlur={(value) => input.onBlur(input.value)}
    />
  );
}

const RenderTimePicker = (props) => {
  const {input, meta, ...rest} = props;

  return (
    <TimePicker
      {...input}
      {...rest}
      format={'HH:mm'}
      showSecond={false}
      allowEmpty={false}
      clearIcon={undefined}
      value={input.value ? moment(input.value, 'HH:mm:ss') : undefined}
      onChange={(time) => input.onChange(time.format('HH:mm:ss')) }
      hideDisabledOptions
    />
  );
}

const RadioBox = (props) => {
  const {
      input,
      name,
      items,
      meta: { touched, error }
    } = props;

    return (
      <div className="flex flex-1 flex-col">
        <div className="flex-1 flex items-center pt-2">
          {items.map((item) => (
            <label key={item.value}>
              <input
                onChange={input.onChange}
                checked={ input.value === item.value }
                name={name}
                type="radio"
                value={item.value}
              />
              <span className="text-sm text-grey-700 ml-2 mr-8 leading-none">{item.label}</span>
            </label>
          ))}
        </div>
        {
          touched && error ? (
            <span className="input-field-error-text mt-px">{error}</span>
          ) : null
        }
      </div>
    )
}

class UserForm extends Component {
  render() {
    const {
      showStatusAndRole,
      showLanguage,
      backButtonText,
      onPressBackButton,
      change,
      handleSubmit,
      pristine,
      countryOptions,
      timezoneOptions,
      onSubmit,
      submitButtonText
    } = this.props;
    const {
      photo,
      t
    } = this.props;

    return (
      <div className="flex flex-col overflow-hidden">
        <div className={`flex flex-none items-center justify-between py-2 px-4 bg-yellow-100`}>
          {
            backButtonText ? (
              <div onClick={onPressBackButton} className="flex items-center cursor-pointer">
                <img
                  className="mr-2 left-icon"
                  src={backIcon}
                  alt="Back"/>
                <span className="leading-none text-sm font-bold text-brown-600">{t('common.buttons.back_to_overview')}</span>
              </div>
            ) :
            !pristine ? (
              <div className="flex items-center cursor-pointer">
                <span className="leading-none text-sm font-bold text-brown-600">{t('common.labels.unsaved_changes')}</span>
              </div>
            ) : <div />
          }

          <div onClick={handleSubmit(onSubmit)} className="flex flex-col h-8 justify-center rounded-sm bg-yellow-700 hover:bg-yellow-400 px-4 cursor-pointer">
            <span className="leading-none flex text-sm text-white">{submitButtonText}</span>
          </div>
        </div>
        <div className="flex flex-col bg-white overflow-y-scroll">
          <div className="flex flex-none flex-col p-5 border-b border-grey-400">
            <span className="text-sm text-grey-800 font-bold mb-2">{t('common.labels.personal_details')}</span>

            <div className="flex flex-1 flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.profile_photo')}</span>
              </div>
              <div className="flex-1_6">
                <div className="flex border rounded-sm border-grey-400 p-3">
                  {
                    photo ? (
                      <img
                        className="h-20 w-20 object-cover mr-3"
                        src={(typeof photo === 'string') ? photo : URL.createObjectURL(photo)}
                        alt={t('common.labels.profile_photo')}/>
                    ) : (
                      null
                    )
                  }
                  <Field
                    name="photo"
                    id="photo"
                    component={FileInputField}
                    className={"hidden"}
                  />

                  <label htmlFor="photo" className={"btn-blue-6 focus:outline-none mr-3 cursor-pointer"}>
                    {t('common.buttons.upload')}
                  </label>

                  <button onClick={() => change("photo", null)} className={"cursor-pointer rounded-sm border border-red-500 bg-white text-sm text-red-500 px-4 h-6 flex flex-col justify-center leading-none focus:outline-none"}>
                    {t('common.buttons.remove')}
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.name')}</span>
                <span className="text-sm text-red-500 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
              </div>
              <div className="flex-1_6">
                <div className="flex">
                  <Field
                    name="first_name"
                    component={InputField}
                    type="text"
                    placeholder={t('common.labels.first_name')}
                  />
                  <div className="w-4"/>
                  <Field
                    name="last_name"
                    component={InputField}
                    type="text"
                    placeholder={t('common.labels.last_name')}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.email')}</span>
                <span className="text-sm text-red-500 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
              </div>
              <div className="flex-1_6">
                <Field
                  name="email"
                  component={InputField}
                  type="email"
                  placeholder={t('common.labels.email')}
                />
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.title')}</span>
              </div>
              <div className="flex-1_6">
                <Field
                  name="title"
                  component={InputField}
                  type="text"
                  placeholder={t('common.labels.title')}
                />
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.phone_number')}</span>
              </div>
              <div className="flex-1_6">
                <div className="flex">
                  <div className="w-125px">
                    <Field
                      name="phone_code"
                      component={RenderCountrySelectInput}
                    />
                  </div>
                  <div className="w-4"/>
                  <div className="flex-2">
                    <Field
                      name="phone"
                      component={InputField}
                      type="text"
                      placeholder={t('common.labels.phone_number')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.country_of_residence')}</span>
              </div>
              <div className="flex-1_6">
                <Field
                  name="countries"
                  component={RenderSelectInput}
                  options={countryOptions}
                  isMulti
                  isClearable={false}
                  placeholder={t('common.placeholders.select_country')}
                />
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 ">{t('common.labels.gender')}</span>
                <span className="text-sm text-red-500 mb-2 ml-1">({t('common.labels.required')})</span>
              </div>
              <div className="flex-1_6 flex flex-col">
                <Field
                  name="gender"
                  component={RadioBox}
                  items={[
                    { value: "male", label: t('common.labels.male') },
                    { value: "female", label: t('common.labels.female')},
                    { value: "other", label: t('common.labels.other') },
                  ]}
                />
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.biography')}</span>
              </div>
              <div className="flex-1_6">
                <Field
                  name="bio"
                  component={InputField}
                  type="text"
                  placeholder={t('common.labels.biography')}
                />
              </div>
            </div>
          </div>

          {
            showStatusAndRole ? (
              <div className="flex flex-none flex-col p-5 border-b border-grey-400">
                <span className="text-sm text-grey-800 font-bold mb-2">{t('common.labels.status_and_role')}</span>

                <div className="flex flex-row mb-4">
                  <div className="flex-1 flex pt-3">
                    <span className="text-sm text-grey-700 mb-2">{t('common.labels.account_status')}</span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      name="is_active"
                      component={RadioBox}
                      items={[
                        { value: "1", label: t('common.labels.active') },
                        { value: "0", label: t('common.labels.inactive')}
                      ]}
                    />
                  </div>
                </div>

                <div className="flex flex-row">
                  <div className="flex-1 flex pt-3">
                    <span className="text-sm text-grey-700 mb-2">{t('common.labels.role')}</span>
                  </div>
                  <div className="flex-1_6">
                    <Field
                      name="role"
                      component={RadioBox}
                      items={[
                        { value: "2", label: t('common.roles.member') },
                        { value: "1", label: t('common.roles.admin')}
                      ]}
                    />
                  </div>
                </div>
              </div>
            ) : null
          }

          <div className="flex flex-none flex-col p-5 border-b border-grey-400">
            <span className="text-sm text-grey-800 font-bold mb-2">{t('common.labels.working_hours')}</span>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.timezone')}</span>
                <span className="text-sm text-red-500 mb-2 ml-1 leading-none">({t('common.labels.required')})</span>
              </div>

              <div className="flex-1_6">
                <Field
                  name="timezone"
                  component={RenderSelectInput}
                  options={timezoneOptions}
                  placeholder={t('common.labels.timezone')}
                />
              </div>
            </div>

            <div className="flex flex-row mb-5">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2 leading-none">{t('common.labels.time')}</span>
              </div>
              <div className="flex-1_6">
                <div className="flex">
                  <div className="flex border border-gray-400 rounded w-full overflow-hidden">
                    <div className="w-16 flex flex-1 bg-grey-300 border-r border-grey-400 justify-center items-center">
                      <span className="text-sm text-black leading-none">{t('common.labels.From')}</span>
                    </div>
                    <Field
                      name="time_from"
                      component={RenderTimePicker}
                    />
                  </div>
                  <div className="w-5 flex-none"/>
                  <div className="flex border border-gray-400 rounded w-full overflow-hidden">
                    <div className="w-16 flex flex-1 bg-grey-300 border-r border-grey-400 justify-center items-center">
                      <span className="text-sm text-black leading-none">{t('common.labels.till')}</span>
                    </div>
                    <Field
                      name="time_to"
                      component={RenderTimePicker}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="flex-1 flex pt-3">
                <span className="text-sm text-grey-700 mb-2">{t('common.labels.working_days')}</span>
              </div>
              <div className="flex-1_6">
                <div className="flex-1 flex flex-col pt-2">
                  <div className="flex">
                    <label className="flex-1">
                      <Field
                        name="work_days.1"
                        component='input'
                        type="checkbox"
                      />
                      <span className="text-sm text-grey-700 ml-2 mr-6 leading-none">{t('weekdays.1')}</span>
                    </label>
                    <label className="flex-1">
                      <Field
                        name="work_days.2"
                        component='input'
                        type="checkbox"
                      />
                      <span className="text-sm text-grey-700 ml-2 mr-6 leading-none">{t('weekdays.2')}</span>
                    </label>
                    <label className="flex-1">
                      <Field
                        name="work_days.3"
                        component='input'
                        type="checkbox"
                      />
                      <span className="text-sm text-grey-700 ml-2 leading-none">{t('weekdays.3')}</span>
                    </label>
                  </div>
                  <div className="flex">
                  <label className="flex-1">
                    <Field
                      name="work_days.4"
                      component='input'
                      type="checkbox"
                    />
                    <span className="text-sm text-grey-700 ml-2 mr-6 leading-none">{t('weekdays.4')}</span>
                  </label>
                  <label className="flex-1">
                    <Field
                      name="work_days.5"
                      component='input'
                      type="checkbox"
                    />
                    <span className="text-sm text-grey-700 ml-2 mr-6 leading-none">{t('weekdays.5')}</span>
                  </label>
                  <label className="flex-1">
                    <Field
                      name="work_days.6"
                      component='input'
                      type="checkbox"
                    />
                    <span className="text-sm text-grey-700 ml-2 leading-none">{t('weekdays.6')}</span>
                  </label>
                  </div>
                  <div className="flex">
                    <label className="flex-1">
                      <Field
                        name="work_days.7"
                        component='input'
                        type="checkbox"
                      />
                      <span className="text-sm text-grey-700 ml-2 mr-6 leading-none">{t('weekdays.7')}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            showLanguage ? (
              <div className="flex flex-none flex-col p-6 border-b border-grey-400">
                <span className="text-sm text-grey-800 font-bold mb-2">{t('common.labels.language')}</span>
                <div className="flex flex-row">
                  <div className="flex-1 flex pt-3">
                    <span className="text-sm text-grey-700 mb-2 ">{t('common.labels.language')}</span>
                  </div>
                  <div className="flex-1_6 flex flex-col">
                    <Field
                      name="locale"
                      component={RadioBox}
                      items={[
                        { value: "en-US", label: t('language.english') },
                        { value: "zh-CN", label: t('language.chinese') }
                      ]}
                    />
                  </div>
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const {
    first_name,
    last_name,
    email,
    gender,
    timezone
  } = values;
  const { t } = props;


  if (!email)
    errors['email'] = `Email ${t('validation.error.required')}`;
  else if (!validator.isEmail(email))
    errors['email'] = t('validation.error.email_invalid');

  if (!first_name)
    errors['first_name'] = `First Name ${t('validation.error.required')}`;

  if (!last_name)
    errors['last_name'] = `Last Name ${t('validation.error.required')}`;

  if (!gender)
    errors['gender'] = `Gender ${t('validation.error.required')}`;

  if (!timezone)
    errors['timezone'] = `Timezone ${t('validation.error.required')}`;

  return errors
}

const initialValues =  {
  first_name: null,
  last_name: null,
  email: null,
  title: '',
  bio: '',
  gender: null,
  role: null,
  is_active: null,
  time_from: null,
  time_to: null,
  countries: null,
  timezone: null,
  photo: null,
  phone_code: null,
  phone: '',
  work_days: []
}

export { UserForm, validate, initialValues };
