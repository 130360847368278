import { UserForm, userFormValidation, userFormInitialValues } from '../../forms';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('addUserForm')

function mapStateToProps(state) {
  return {
    photo: selector(state, 'photo')
  }
}

export default withTranslation()(reduxForm({
  form: 'addUserForm',
  initialValues: userFormInitialValues,
  validate: userFormValidation
})(connect(mapStateToProps)(UserForm)));
