import React, { Component } from 'react';
import {
  Select
} from '../../components';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import PubNubReact from 'pubnub-react';
import md5 from 'md5';

class DistrictSchools extends Component {
  constructor(props) {
    super(props);

    const currentYear = moment().get('year');
    const defaultYearOption = { value: currentYear, label: currentYear };
    this.channels = {};
    this.reports = {};

    this.pubnub = new PubNubReact({
      subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIPTION_KEY
    });

    this.state = {
      reportToDownload: null,
      selectedReport: null,
      selectedYearTo: defaultYearOption,
      selectedYearFrom: defaultYearOption,
      errorMessages: {},
      tenantsDownloading: []
    };

    this.pubnub.init(this);
  }

  componentWillUnmount() {
    this.pubnub.unsubscribe({
      channels: _.keys(this.channels)
    });
  }

  _downloadForAllTenants = () => {
    const { tenants, t } = this.props;
    const { selectedReport } = this.state;

    if (!selectedReport) {
      const errorMessages = {
        report: `Report ${t('validation.error.required')}`
      };

      this.setState({ errorMessages });

      return;
    }

    this.setState({ tenantsDownloading: _.map(tenants, (tenant) => tenant.id) });
    _.each(tenants, this._downloadReportForTenant);
  }

  _downloadForSingleTenant = (tenant) => {
    const { tenants, t } = this.props;
    const { selectedReport, tenantsDownloading } = this.state;

    if (!selectedReport) {
      const errorMessages = {
        report: `Report ${t('validation.error.required')}`
      };

      this.setState({ errorMessages });

      return;
    }

    if (!tenantsDownloading.includes(tenant.id))
      this.setState({ tenantsDownloading: [...tenantsDownloading, tenant.id] });

    this._downloadReportForTenant(tenant);
  }

  _downloadReportForTenant = (tenant) => {
    const { selectedReport, selectedYearFrom, selectedYearTo, tenantsDownloading } = this.state;
    const { generateReport, t } = this.props;

    generateReport(selectedReport.value, {
      tenant_id: tenant.id,
      year_from: selectedYearFrom.value,
      year_to: selectedYearTo.value,
    }).then(response => {
      const { report } = response.data.status.details;

      this.reports[report.id + "_" + tenant.id + "_" + report.last_generated_report.id] = report;
    });

    const channel = md5('reports_' + tenant.id);

    if (!this.channels[channel]) {
      this.channels[channel] = tenant.id;

      this.pubnub.subscribe({
        channels: [channel],
        withPresence: true
      });
    }

    this.pubnub.getMessage(channel, this._onPubNubMessage);
  }

  _onPubNubMessage = (params) => {
    const { getReportDownloadURL } = this.props;
    const { message, channel } = params;
    const reportToDownload = this.reports[message.data.report_id + "_" + this.channels[channel] + "_" + message.data.generated_report_id]

    if (reportToDownload)
      getReportDownloadURL(message.data.report_id, reportToDownload.last_generated_report.id, { tenant_id: this.channels[channel] })
        .then(response => {
          const tenantsDownloading = [...this.state.tenantsDownloading];
          _.remove(tenantsDownloading, () => this.channels[channel]);
          this.setState({ tenantsDownloading });

          if (response.data.status.details.url) {
            window.location.href = response.data.status.details.url;
            delete this.reports[message.data.report_id + "_" + this.channels[channel] + "_" + message.data.generated_report_id]
          }
        })
        .catch(error => {
          const tenantsDownloading = [...this.state.tenantsDownloading];
          _.remove(tenantsDownloading, this.channels[channel]);

          this.setState({ tenantsDownloading });
        });
  }

  _handleReportChange = (selectedReport) => {
    this.setState({
      selectedReport,
      errorMessages: {}
    });
  }

  render() {
    const { tenants, loading, reports, t } = this.props;
    const { selectedReport, selectedYearTo, selectedYearFrom, errorMessages, tenantsDownloading } = this.state;
    const reportOptions = _.map(reports, (report) => ({ label: report.name, value: report.id }) );
    const graduationYearRange = _.map(_.range(1990, 2026, 1), (year) => ({
      value: year, label: year
    }));

    return (
      <div className="flex flex-col flex-none max-w-4xl">
        <span className="font-bold text-base mt-8">{t('dashboard.reports')}</span>
        <div className="flex flex-row flex-none mb-1 mt-4 items-center">
          <div className="flex-1 pr-4">
            <span className="font-bold text-sm">{t('dashboard.select_a_report')}</span>
          </div>
          <div className="flex-1">
            <span className="font-bold text-sm">{t('dashboard.set_graduation_years')}</span>
          </div>
        </div>

        <div className="flex mt-2 px-4">
          <div className="flex-1 pr-5">
            <Select
              error={errorMessages['report']}
              placeholder={t('dashboard.class_profiles')}
              value={selectedReport}
              onChange={this._handleReportChange}
              options={reportOptions}
            />
          </div>
          <div className="flex-1">
          <div className="flex">
            <div className="flex w-40">
              <div className="w-16 rounded-l flex bg-grey-300 border border-gray-400 justify-center items-center">
                <span className="text-sm text-black leading-none">{t('common.labels.from')}</span>
              </div>
              <div className="flex-1 rounded-r border border-l-0 border-gray-400">
                <Select
                  borderNone
                  placeholder={t('common.labels.from')}
                  value={selectedYearFrom}
                  onChange={(selectedYearFrom) => this.setState({ selectedYearFrom }) }
                  options={graduationYearRange}
                />
              </div>
            </div>
            <div className="w-5"/>
            <div className="flex w-40">
              <div className="w-16 rounded-l flex bg-grey-300 border border-gray-400 justify-center items-center">
                <span className="text-sm text-black leading-none">{t('common.labels.to')}</span>
              </div>
              <div className="flex-1 rounded-r border border-l-0 border-gray-400">
                <Select
                  borderNone
                  placeholder={t('common.labels.to')}
                  value={selectedYearTo}
                  onChange={(selectedYearTo) => this.setState({ selectedYearTo }) }
                  options={graduationYearRange}
                />
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="dashboard-table-header">
          <div className="flex flex-row flex-1 pl-1 pr-4 mr-10px">
            <span className="text-sm text-grey-800 font-bold">{t('dashboard.district_school')}</span>
          </div>
          <div className="flex flex-row flex-none w-32">
          </div>
        </div>
        <div className="flex flex-col flex-none">
          {
            tenants.map(tenant => (
              <div key={tenant.id} className="flex flex-row flex-none dashboard-table-row">
                <div className="flex flex-row flex-1 items-center pl-1 mr-10px">
                  <span className="font-medium text-sm text-grey-800">{tenant.name}</span>
                </div>
                <div className="flex flex-row flex-none w-32 items-center">
                <button disabled={tenantsDownloading.includes(tenant.id)} onClick={() => this._downloadForSingleTenant(tenant)} className="btn-primary justify-center">
                  {
                    tenantsDownloading.includes(tenant.id) ? (
                      <Loader
                        type="Oval"
                        color="#FFFFFF"
                        height="18"
                        width="18"
                      />
                    ) : (
                      <span className="font-medium text-sm leading-none">{t('dashboard.buttons.download')}</span>
                    )
                  }
                </button>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withTranslation()(DistrictSchools);
