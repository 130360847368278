const week = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const accountStatus = {
  1: 'Active',
  2: 'Inactive'
}

export { week, accountStatus };
