export default {
  misc: {
    countries: [],
    timezones: [],
    reports: [],
  },
  tenants: {
    data: [],
    loading: false,
  },
  user: {
    authToken: null,
    data: null,
    sessionId: null,
    loading: false,
    district: null,
    metabase: {
      url: null,
      loading: false,
      dashboards: [],
    },
  },
  users: {
    data: [],
    loading: false,
    matched: 0,
    total: 0,
    active: 0,
    inactive: 0,
    params: {
      page: 1,
      per_page: 20,
      is_active: true,
      search: "",
      role: 0,
      order: { by: "first_name", desc: false },
    },
  },
};
