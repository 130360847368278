import * as WebService from '../../web-services';
import { TenantsCreators } from '../actions';

export function getTenants(params) {
  return function(dispatch, getState) {
    const {
      user: { authToken },
    } = getState();
    dispatch(TenantsCreators.getTenantsRequest());

    return WebService.getTenants(authToken)
      .then(response => {
        dispatch(TenantsCreators.getTenantsSuccess(response.data.tenants));

        return response;
      })
      .catch(error => {
        dispatch(TenantsCreators.getTenantsFailure());

        throw error;
      });
  }
}
