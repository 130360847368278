import AddUserScreen from './add-user-screen';
import { connect } from 'react-redux';
import { createUser } from '../../redux/thunk';

function mapStateToProps(state) {
  return {
    countries: state.misc.countries,
    timezones: state.misc.timezones,
  }
}

const mapDispatchToProps = {
  createUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserScreen);
