import SignInScreen from './sign-in-screen';
import { connect } from 'react-redux';
import { signIn } from '../../redux/thunk';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  signIn
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);
